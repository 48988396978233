<template>
<table class="table">
  <tr>
    <th class="d-table-cell sortable" @click="sortBy('project_id')">
      <i v-if="orderBy.column === 'project_id'" class="bg-transparent fas" :class="sortIcon"></i>
      Project
    </th>
    <th class="d-table-cell">Activities</th>
    <th class="d-table-cell sortable" @click="sortBy('created_at')">
      <i v-if="orderBy.column === 'created_at'" class="bg-transparent fas" :class="sortIcon"></i>
      Date
    </th>
    <th class="d-table-cell sortable" @click="sortBy('hours')">
      <i v-if="orderBy.column === 'hours'" class="bg-transparent fas" :class="sortIcon"></i>
      Hours
    </th>
    <th class="d-table-cell sortable" v-if="showCosts" :title="costsMessage()" @click="sortBy('costs')">
      <i v-if="orderBy.column === 'costs'" class="bg-transparent fas" :class="sortIcon"></i>
      Costs *
    </th>
  </tr>
  <template v-for="user in users">
    <tr :key="`u${user.id}`">
      <th colspan="3" class="bg-company-primary d-table-cell">{{user.name}}</th>
      <th class="bg-company-primary d-table-cell">{{totalBy('user_id', parseInt(user.id, 10), 'hours')}}</th>
      <th v-if="showCosts" class="bg-company-primary d-table-cell">${{totalBy('user_id', parseInt(user.id, 10), 'costs').toFixed(2) | formatNumber}}</th>
    </tr>
    <tr v-for="hour in filterBy('user_id', parseInt(user.id, 10))" :key="hour.id">
      <td>{{!!hour.project ? hour.project.name :'Internal hours'}}</td>
      <td class="text-wrap" v-html="hour.activities" v-if="hour.activities"></td>
      <td v-else>-</td>
      <td>
        <i v-if="isHoliday(hour)" title="Holiday" class="fa-regular fa-calendar-xmark" :class="isHoliday(hour) && hour.hours > 0 ? 'text-danger' : ''"></i>
        {{$displayDate(hour.date)}}
      </td>
      <td>{{hour.hours}}</td>
      <td v-if="showCosts">${{hour.costs | formatNumber}}</td>
    </tr>
  </template>
  <tr v-if="users && users.length === 0">
    <td class="text-center" :colspan="showCosts ? 6 : 5">No records found</td>
  </tr>
  <SkeletonRow v-if="hours && hours.paginatorInfo.hasMorePages" :colspan="showCosts ? 6 : 5" :executeScroll="hours != undefined" @showMore="showMore"></SkeletonRow>
  <tr v-if="users && users.length > 0">
    <td class="text-end" colspan="3">
      <strong>Total</strong>
    </td>
    <td><strong>{{total}}</strong></td>
    <td v-if="showCosts"><strong>${{totalCosts | formatNumber}}</strong></td>
  </tr>
</table>
</template>

<script>

import Helper from '../Helper';

export default {
  extends: Helper,
  props: ['hours', 'holidays', 'orderBy', 'show_costs'],
};
</script>
