<template>
  <div v-if="answeredThisMonth()" class="container-fluid">
    <div class="text-center row pt-3">
      <div class="col-md-12">
        <h3>WE LOVE FEEDBACK</h3>
        <h6>It'll be really quick, we promise</h6>
      </div>
    </div>
    <div class="border-top px-2 py-2 bg-company-secondary">
        <p class="h5">
          Thank you for working with {{ company.name }} Nearshoring IT Services.<br>
          We’re always looking at how we can make your experience better.<br>
          We appreciate your input.
        </p>
    </div>
    <div class="text-start border-top">
      <div class="mb-0 h5 company-background-color px-3 py-2">1) Based on your most recent experiences, on a scale of 0-10, how likely would recommend {{ company.name }} to a friend or colleague?</div>
      <div class="row border-top d-flex align-items-center m-0">
        <div class="col border text-center fw-bolder sortable pb-3 pt-3 bg-company-primary-ligth" :key="`score-${index}`" :class="{'bg-company-secondary': form.score === index}" v-for="index in scale" @click="score(index)">
          {{index}}
        </div>
      </div>
    </div>
    <div class="border-top">
      <div class="text-center py-2">
        <div class="row">
          <div class="col text-start">
            (0: Not at all likely)
          </div>
          <div class="col text-end">
            (10: extremely likely)
          </div>
        </div>
      </div>
    </div>
    <div class="company-background-color">
      <h5 class="border mb-0 px-3 py-2">2) What’s the main reason for your Score?</h5>
      <div class="pt-2">
        <trix-editor @trix-change="form.reason = $event.target.value; $forceUpdate()"></trix-editor>
      </div>
    </div>
    <div class="d-flex justify-content-between pt-2 pb-3 border-top">
        <div>
          <p class="m-0">Warm Regards,</p>
          <p class="m-0">{{ company.name }} Team</p>
        </div>
        <div>
          <button :disabled="form.score === null" type="button" class="btn btn-company-primary" @click="save()" >
            <i v-if="loading" class="fas fa-sync fa-spin"></i>
            <i v-else class="fas fa-paper-plane"></i>
            Submit
          </button>
          <button :disabled="form.score === null" type="button" class="btn btn-company-primary" @click="save(true)" >
            <i v-if="loading" class="fas fa-sync fa-spin"></i>
            <i v-else class="fas fa-mask"></i>
            Submit (Anonymously)
          </button>
        </div>
      </div>
  </div>
</template>
<script>

import dayjs from 'dayjs';
import SUBMIT_INTERNAL_NET_PROMOTER_SCORE_FORM from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import ME_QUERY from './queries';

export default {
  data() {
    return {
      company: window.Company,
      loading: false,
      form: {
        score: null,
        reason: null,
      },
      me: {
        last_known_internal_net_promoter_score_date: '',
      },
    };
  },
  computed: {
    localRecord() {
      return structuredClone(this.form);
    },
    scale() {
      return _.range(11);
    },
  },
  apollo: {
    me: {
      query: ME_QUERY,
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    score(selectedScope) {
      this.form.score = selectedScope;
      document.querySelector('trix-editor').focus();
    },
    save(anonymous = false) {
      this.loading = true;
      this.$apollo.mutate({
        mutation: SUBMIT_INTERNAL_NET_PROMOTER_SCORE_FORM,
        variables: {
          score: this.localRecord.score, reason: this.localRecord.reason, anonymous,
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).finally(() => {
        this.$toasted.success('Thank You!');
        this.loading = false;
        this.$apollo.queries.me.refetch();
      });
    },
    answeredThisMonth() {
      if (this.me.last_known_internal_net_promoter_score_date != null) {
        if ((dayjs().format('YYYY')) === (dayjs(this.me.last_known_internal_net_promoter_score_date, 'YYYY-MM-DD').format('YYYY'))) {
          return ((dayjs().format('MM')) > (dayjs(this.me.last_known_internal_net_promoter_score_date, 'YYYY-MM-DD').format('MM')));
        }
        return ((dayjs().format('YYYY')) > (dayjs(this.me.last_known_internal_net_promoter_score_date, 'YYYY-MM-DD').format('YYYY')));
      }
      return true;
    },
  },
};
</script>
