<style scoped>
.chart-row {
  height: 80vh;
}
</style>
<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ol class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                <router-link to="/indicators">
                  Indicators
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page" v-if="indicator">
                {{indicator.name}}
              </li>
            </ol>
          </div>
        </div>
        <div class="container-fluid mt-3">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['dateRanges']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12">
        <div class="chart-row" id="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Highcharts from 'highcharts';
import { Search } from '../shared';
import SearchBuilder from '../../shared/SearchBuilder';
import { INDICATOR_QUERY } from './queries';

export default {
  components: { Search },
  data() {
    return {
      search: {},
    };
  },
  apollo: {
    indicator: {
      query: INDICATOR_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    details() {
      return [...this.indicator.details.data].reverse();
    },
    categories() {
      return _.map(this.details, 'created_at');
    },
    series() {
      return _.map(this.details, (v) => parseFloat(v.value));
    },
    subtitle() {
      const startDate = this.$displayDate(_.head(this.details).created_at);
      const endDate = this.$displayDate(_.last(this.details).created_at);
      return `From <strong>${startDate}</strong> To <strong>${endDate}</strong>`;
    },
  },
  watch: {
    indicator() {
      this.drawChart();
    },
  },
  methods: {
    drawChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'area',
        },
        title: {
          text: this.indicator.name,
        },
        subtitle: {
          text: this.subtitle,
        },
        tooltip: {
          backgroundColor: 'white',
          valuePrefix: this.indicator.prefix || undefined,
          valueSuffix: this.indicator.suffix || undefined,
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0,
        },
        plotOptions: {
          series: {
            dataLabels: {
              enabled: true,
            },
          },
          area: {
            marker: {
              enabled: true,
              symbol: 'circle',
              radius: 4,
              fillColor: '#ec663b',
            },
          },
        },
        legend: {
          enabled: true,
          itemStyle: {
            color: '#000000',
          },
        },
        series: [
          {
            name: this.indicator.name,
            data: this.series,
            lineColor: '#ec663b',
            color: '#ffe9e1',
          },
        ],
      });
    },
  },
};
</script>
