import { gql } from 'apollo-boost';
import HOLIDAY_FRAGMENT from '../holidays/fragments';

export default gql`
  query($year: Int, $start_date: DateTime, $end_date: DateTime, $country_id: ID, $user_id: ID) {
    holidaysByYear(
      year: $year,
      start_date: $start_date,
      end_date: $end_date,
      country_id: $country_id,
      user_id: $user_id,
      orderBy: { column: "date", order: ASC }
      ) @connection(key: "holidaysByYear", filter: ["year", "country_id", "user_id"]) {
        ...holiday
      }
  }
  ${HOLIDAY_FRAGMENT}
`;
