<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">PTO Calendar</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['clients', 'projects', 'users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div v-if="approved_paid_time_off_request_days">
      <FullCalendar ref="calendar"
        :options="calendarOptions" />
    </div>
  </div>
</template>

<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import dayjs from 'dayjs';
import PTO_DAYS_QUERY from './queries';
import { ErrorHandler, SearchBuilder } from '../../../shared';
import { Search, SpinnerRouterLink } from '../../shared';

export default {
  components: { FullCalendar, Search, SpinnerRouterLink },
  apollo: {
    approved_paid_time_off_request_days: {
      query: PTO_DAYS_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          year: parseInt(this.year, 10),
          month: parseInt(this.month, 10),
          ...SearchBuilder.build(this.search),
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    change() {
      const api = this.$refs.calendar.getApi();
      this.year = api.getDate().getFullYear().toString();
      this.month = (api.getDate().getMonth() + 1).toString();
    },
  },
  watch: {
    approved_paid_time_off_request_days() {
      this.calendarOptions.events = this.approved_paid_time_off_request_days.map(
        (pto) => ({
          id: pto.id,
          title: `${pto.paid_time_off_request.user.name} ${pto.type}`,
          date: pto.date,
        }),
      );
    },
  },
  data() {
    return {
      search: { only_my_projects: false },
      year: dayjs().format('YYYY'),
      month: dayjs().format('M'),
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [],
        weekends: false,
        eventsSet: this.change,
      },
    };
  },
};
</script>
