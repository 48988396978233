<template>
  <ul class="list-group list-group-flush">
    <template v-for="(day, index) in Week.weekdays">
      <li v-if="record.has_weekends ? true : (index != 0 && index != 6)" :key="index"
        class="list-group-item list-group-item-action sortable px-0" :class="{
          'active': value.day() === index,
          'bg-light': (value.day() != index && (index === 0 || index === 6))
        }" @click="$emit('input', Week.get(index))" @dblclick="$emit('toggle', index)">
        <div class="row d-flex p-0 justify-content-center user-select-none"
          :class="{ 'my-lg-2 pb-lg-1': (!record.has_weekends && record.id) }">
          <span class="col-4 p-0 text-start">{{ day.format('dddd') }}</span>
          <div class="col-2 d-flex justify-content-center align-items-center">
            <span v-if="loading" class="position-absolute">
              <div class="spinner-grow text-secondary m-0" role="status"><span class="sr-only">...</span></div>
            </span>
            <template v-else>
              <span v-if="days[index].activities"
                :title="days[index].activities" style="opacity:0.1"
                :class="{ 'text-black-50': value.day != index }"
                class="h1 mt-2 mb-0 position-absolute"
              ><i class="fas fa-comment-alt"/></span>
              <span v-if="days[index].preview"            class="text-nowrap z-index-3 position-absolute"
                :class="value.day == index ? 'text-white-50' : 'text-body-secondary'"
              >{{ days[index].preview }}</span>
            </template>
          </div>
          <span class="col-4 p-0 text-end">{{ day.format('YYYY-MM-DD') }}</span>
        </div>
      </li>
    </template>
  </ul>
</template>

<script setup>
import dayjs from 'dayjs';
import { computed } from 'vue';
import { State, Week } from '../../State';

const props = defineProps({
  value: { type: Object, default: dayjs() },
  index: { type: Number, required: true },
});

const record = computed(() => State.value.records[props.index]);
const loading = computed(() => record.value.isSaving || State.value.isLoading);
const days = computed(() => Week.value.weekdays.map((weekday) => {
  const day = record.value.logs.find((o) => weekday.isSame(o.created_at, 'day')) || {};
  if (day.hours) {
    day.preview = day.hours;
    day.preview += (day.hours === 1) ? 'HR' : 'HRS';
  } else if (day.activities) day.preview = '...';
  else day.preview = '';
  return day;
}));
</script>
