<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Holidays</SpinnerRouterLink>
          </div>
        </div>
      </div>
    </nav>
    <div class="text-start">
      <button class="btn btn-company-primary" type="button" data-bs-toggle="collapse" data-bs-target="#collapse"
        aria-expanded="false" aria-controls="collapse">
        Year Events
      </button>
      <div class="collapse" id="collapse">
        <div class="p-1 py-1 px-0">
          <ViewCalendar :year="year" :record="teamHolidays" />
        </div>
      </div>
      <FullCalendar ref="calendar" :options="calendarOptions">
        <template v-slot:eventContent="arg">
          <i>{{ arg.event.title }}</i>
        </template>
      </FullCalendar>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import { SpinnerRouterLink } from '../../shared';
import ViewCalendar from '../../vacation/holiday_calendar/view_calendar.vue';
import { ErrorHandler, Flags } from '../../../shared';
import TEAM_HOLIDAYS from './queries';

export default {
  components: {
    SpinnerRouterLink, ViewCalendar, FullCalendar,
  },
  apollo: {
    teamHolidays: {
      query: TEAM_HOLIDAYS,
      errorPolicy: 'all',
      variables() {
        return {
          year: parseInt(this.year, 10),
          country_id: this.search?.country_id,
          project_id: this.search?.project_id,
        };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
  methods: {
    change() {
      const api = this.$refs.calendar.getApi();
      this.year = api.getDate().getFullYear().toString();
    },
  },
  watch: {
    teamHolidays() {
      this.calendarOptions.events = this.teamHolidays.map(
        (holiday) => ({
          id: holiday.id,
          title: `${Flags[holiday.country.name]} ${holiday.name} - ${holiday.country.name}`,
          date: holiday.date,
        }),
      );
    },
  },
  data() {
    return {
      search: {},
      year: dayjs().format('YYYY'),
      calendarOptions: {
        plugins: [dayGridPlugin],
        initialView: 'dayGridMonth',
        events: [],
        weekends: true,
        eventsSet: this.change,
        eventSources: true,
      },
    };
  },
};
</script>
