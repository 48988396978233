<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Turnover Dashboard</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search" :inputs="['dateRanges']" :default_values="defaultDates"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row align-items-center">
      <template v-if="headcountDiff > 0">
        <div class="col-4 text-center text-success">
          <i class="fa-solid fa-users fa-2x"></i>
          <h2>
            HeadCount: +{{headcountDiff}}
          </h2>
        </div>
      </template>
      <template v-if="headcountDiff < 0">
        <div class="col-4 text-center text-danger">
          <i class="fa-solid fa-users fa-2x"></i>
          <h2>
            HeadCount: {{headcountDiff}}
          </h2>
        </div>
      </template>
      <div class="col-4 text-center">
        <i class="fa-solid fa-earth-americas fa-2x"></i>
        <h2>Countries: {{distinctCountries}}</h2>
        <h3></h3>
      </div>
      <div class="col-4" id="why-people-leave-chart"></div>
    </div>
    <hr>
    <div class="row overflow-auto h-50">
      <div class="col-6">
        <ul class="list-group list-group">
          <li class="list-group-item active">{{hires?.length}} People were hired</li>
          <li v-for="hire in hires" :key="`h-${hire.user_id}`" class="list-group-item d-flex justify-content-between align-items-center">
            <p class="m-0" :title="`${hire.user_name} from ${hire.country}`">
              {{flag(hire.country)}}
              <small>
                {{hire.user_name}}
              </small>
            </p>
            <p class="m-0">
              <small class="text-body-secondary ms-2">
                {{$displayDate(hire.event_date)}}
              </small>
            </p>
          </li>
        </ul>
      </div>
      <div class="col-6">
        <ul class="list-group list-group">
          <li class="list-group-item active">{{leavers?.length}} People left the company</li>
          <li v-for="leaver in leavers" :key="`l-${leaver.user_id}`" class="list-group-item d-flex justify-content-between align-items-center">
            <p class="m-0" :title="`${leaver.user_name} from ${leaver.country}`">
              {{flag(leaver.country)}}
              <small>
                {{leaver.user_name}} ({{leaver.termination_reason}})
              </small>
            </p>
            <p class="m-0">
              <small class="text-body-secondary ms-2">
                {{$displayDate(leaver.event_date)}}
              </small>
            </p>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import { Search, SpinnerRouterLink } from '../../shared';
import { SearchBuilder, ErrorHandler, Flags } from '../../../shared';
import TURNOVER_RESULTS from './queries';

export default {
  components: { Search, SpinnerRouterLink },
  data() {
    return {
      search: { },
    };
  },
  computed: {
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'months').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
      };
    },
    distinctCountries() {
      return _.uniqBy(this.turnover, 'country').length;
    },
    hires() {
      return _.filter(this.turnover, { headcount_diff: 1 });
    },
    leavers() {
      return _.filter(this.turnover, { headcount_diff: -1 });
    },
    headcountDiff() {
      return this.hires.length - this.leavers.length;
    },
    series() {
      const categories = _.uniq(_.compact(_.map(this.turnover, 'termination_reason')));
      const data = _.map(categories, (item) => ({ name: item, y: _.filter(this.turnover, { termination_reason: item }).length }));
      return [
        {
          name: 'People',
          colorByPoint: true,
          data,
        },
      ];
    },
  },
  watch: {
    turnover() {
      this.drawChart();
    },
  },
  methods: {
    flag(name) {
      return Flags[name];
    },
    drawChart() {
      if (this.turnover.length === 0) {
        return;
      }
      Highcharts.chart('why-people-leave-chart', {
        chart: {
          type: 'pie',
          height: '50%',
        },
        title: {
          text: 'Why did they leave?',
        },
        series: this.series,
      });
    },
  },
  apollo: {
    turnover: {
      query: TURNOVER_RESULTS,
      errorPolicy: 'all',
      variables() {
        return SearchBuilder.build(this.search);
      },
      skip() {
        return !(this.search.start_date || this.search.end_date);
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
  },
};
</script>
