<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Internal Net Promoter Score</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search" :inputs="['dateRanges']"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <Charts v-if="!$apollo.loading" :informationData="internalNetPromoterScoreStatistics" source="/net_promoter_score/internal_answers"></Charts>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { SearchBuilder } from '../../../shared';
import { Search, SpinnerRouterLink } from '../../shared';
import { INTERNAL_NET_PROMOTER_STATISTICS } from '../queries';
import Charts from '../Charts.vue';

export default {
  components: { Search, Charts, SpinnerRouterLink },
  data() {
    return {
      search: {
        end_date: this.$route.query.end_date || dayjs().add(1, 'months').format('YYYY-MM-DD'),
        start_date: this.$route.query.start_date || dayjs(this.end_date).add(-1, 'years').format('YYYY-MM-DD'),
      },
    };
  },
  apollo: {
    internalNetPromoterScoreStatistics: {
      query: INTERNAL_NET_PROMOTER_STATISTICS,
      errorPolicy: 'all',
      variables() {
        return SearchBuilder.build(this.search);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
