<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Weekly Forecast</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search :search.sync="search"
                      :inputs="['projects', 'clients', 'users', 'dateRanges', 'onlyMyProjectsCheckbox']"
                      :default_values="defaultDates"></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row text-nowrap scroll-x h-100" v-if="projectForecast">
      <div class="col-md-12">
        <table class="table">
          <thead>
            <tr>
              <SortByColumn class="d-table-cell" :sort_by_field="'project'" :orderBy="orderBy" @refresh="refresh">Project</SortByColumn>
              <SortByColumn class="d-table-cell" :sort_by_field="'person'" :orderBy="orderBy" @refresh="refresh">Person</SortByColumn>
              <th class="d-table-cell"></th>
              <th class="text-center d-table-cell">Real vs Expected Hours</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            <tr :key="index" v-for="(row, index) in projectForecast">
              <td>{{row.project}}</td>
              <td>{{row.person}}</td>
              <td class="text-center h3 py-2">
                <span class="text-body-secondary mt-1 d-block"
                  v-if="row.holidays > 0 || row.vacation_days > 0"
                  :title="setTitle(row)">
                  <i class="fas fa-exclamation-circle"></i>
                </span>
              </td>
              <td class="text-center pt-0 pb-0">
                <span>{{row.actual_hours}} out of {{row.expected_hours}} ({{percentage(row.actual_hours, row.expected_hours)}}%)</span>
                <div class="progress" role="progressbar" aria-label="Expected hours" :aria-valuenow="row.hours_per_week" aria-valuemin="0" :aria-valuemax="row.expected_hours">
                  <div class="progress-bar"
                      :class="row.actual_hours < row.expected_hours ? 'bg-danger' : 'bg-success'"
                      :style="{ width: percentage(row.actual_hours, row.expected_hours) + '%' }">
                  </div>
                </div>
              </td>
              <td>
                <Confirmation v-if="row.actual_hours !== row.expected_hours" title="Autofill" :icon="'fa-solid fa-wand-sparkles'" :id="confirmationCriteria(row)" :showLabels="false" @accepted="autoFill"></Confirmation>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </div>
    <Empty v-if="!projectForecast?.length" icon="fas fa-calculator"/>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import PROJECT_FORECAST from './queries';
import AUTOFILL_MUTATION from './mutations';
import {
  Search, SortByColumn, SpinnerRouterLink, Confirmation,
} from '../../shared';
import { SearchBuilder, ErrorHandler } from '../../../shared';

export default {
  components: {
    Search, SortByColumn, SpinnerRouterLink, Confirmation,
  },
  data() {
    return {
      orderBy: { column: 'project', order: 'ASC' },
      search: { only_my_projects: false },
    };
  },
  apollo: {
    projectForecast: {
      query: PROJECT_FORECAST,
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [this.orderBy],
          ...SearchBuilder.build(this.search),
        };
      },
      skip() {
        return !(this.search.start_date || this.search.end_date);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  computed: {
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'weeks').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: dayjs().subtract(1, 'weeks').endOf('isoweek').format('YYYY-MM-DD'),
      };
    },
  },
  methods: {
    confirmationCriteria(row) {
      return {
        user_id: row.user_id,
        project_id: row.project_id,
        start_date: this.search.start_date,
        end_date: this.search.end_date,
      };
    },
    autoFill(autofillCriteria) {
      this.$apollo.mutate({
        mutation: AUTOFILL_MUTATION,
        variables: { autofill: autofillCriteria },
        update: (store) => {
          const variables = { orderBy: [this.orderBy], ...SearchBuilder.build(this.search) };
          const data = store.readQuery({ query: PROJECT_FORECAST, variables });
          data.projectForecast.filter((row) => row.user_id === autofillCriteria.user_id
                                               && row.project_id === autofillCriteria.project_id)
            .forEach((row) => {
              row.actual_hours = row.expected_hours;
            });
          store.writeQuery({ query: PROJECT_FORECAST, variables, data });
          this.$toasted.success('Hours were saved!');
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    refresh() {
      this.$apollo.queries.projectForecast.refetch();
    },
    percentage(actual, expected) {
      if (actual === expected) {
        return 100;
      }
      return ((actual * 100) / expected).toFixed(2);
    },
    setTitle(row) {
      let title = (row.holidays > 0) ? `Holidays: ${row.holidays}\r\n` : '';
      title += (row.vacation_days > 0) ? `PTO days: ${row.vacation_days}` : '';
      return title;
    },
  },
};
</script>
