<template>
  <header class="card-header user-select-none">
    <div class="d-lg-flex">
      <div @click="$emit('input', !value)" class="col-lg m-auto text-center text-lg-start pl-0 sortable">
        <h3 class="font-weight-bold m-0">
          <i class="fas cursor-text text-secondary" :class="{ 'fa-chevron-down': value, 'fa-chevron-up': !value }" />
          {{ record.name || 'Internal Hours' }}
        </h3>
      </div>
      <div class="d-lg-flex col-lg text-center justify-content-end align-records-center my-auto">
        <template v-if="record.id">
          <div class="form-check form-switch d-flex" :class="{ 'gap-2': State.isLoading || record.isSaving }">
            <div v-if="State.isLoading || record.isSaving" class="spinner-border text-secondary spinner-grow-sm mt-2" role="status">
              <span class="sr-only">Loading...</span>
            </div>
            <input v-else v-model="record.has_weekends" :id="`layout${record.id}`" type="checkbox" class="form-check-input m-auto">
            <label class="form-check-label" :for="`layout${record.id}`">Weekends</label>
          </div>
        </template>
        <div class="d-flex justify-content-center ms-lg-3">
          <button class="btn btn-company-primary" v-if="fillHours === null" @click="fillHours = 8"
            :disabled="State.isLoading || record.isSaving">
            <i class="fas fa-list-alt"></i>
            Autofill
          </button>
          <div class="d-flex justify-content-end" v-if="fillHours !== null">
            <input type="number" class="col-2 form-control text-center w-25" @keydown="autoFill"
              :placeholder="fillHours || 0" v-model="fillHours" min=0 v-focus>
            <button class="col-3 btn btn-company-primary ms-2" @click="autoFill()">
              <i class="fas fa-list-alt" />
              Fill
            </button>
            <button type="button" class="col-3 btn btn-company-secondary ms-2" @click="fillHours = null">
              <i class="fas fa-times" />
              Cancel
            </button>
          </div>
        </div>
        <button v-if="State.isLoading || record.isSaving" class="btn btn-company-primary ms-lg-3 d-flex gap-1 items-center" disabled>
          <template v-if="State.isLoading">
            <i class="fas fa-save" /> Save
            <div class="position-relative me-3">
              <div class="spinner-border text-secondary spinner-grow-sm position-absolute" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="position-relative me-3">
              <div class="spinner-border text-secondary spinner-grow-sm position-absolute" role="status">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
            <span class="me-2">Saving...</span>
          </template>
        </button>
        <button v-else-if="!value" class="btn btn-company-primary ms-lg-3" style="min-width: 100px;" @click="save">
          <i class="fas fa-save" /> Save {{ record.draftHours }}
        </button>
      </div>
    </div>
  </header>
</template>

<script>
import dayjs from 'dayjs';
import { State, Week } from '../../State';
import LOG_MY_TIME from '../../mutations';

export default {
  props: {
    value: { type: Boolean, required: true },
    index: { type: Number, required: true },
  },
  data() { return { State, Week, fillHours: null }; },
  computed: {
    record() {
      return this.State.records[this.index];
    },
  },
  watch: {
    fillHours(value) {
      if (value === null) return;
      this.fillHours = Number.isNaN(value) ? 0 : Number.parseFloat(value);
    },
  },
  methods: {
    save() {
      this.record.isSaving = true;
      this.$apollo.mutate({
        mutation: LOG_MY_TIME,
        variables: this.buildPayload(),
      }).catch((error) => {
        this.$toasted.error(`Unable to save records: ${error.message}`);
        this.record.isSaving = false;
      }).then(({ data: { response } }) => {
        this.$toasted.success(`Hours for ${this.record.id ? this.record.name : 'Internal Time'} were saved!`);
        this.State.update(this.index, response);
      });
    },
    buildPayload() {
      const keys = ['id', 'hours', 'activities', 'created_at', 'bucket_estimation_id'];
      const record = { ...this.record };
      if (!record.has_weekends) {
        const { logs, ids } = record.logs.reduce((response, log) => {
          if (!this.Week.isWeekend(log.created_at)) response.logs.push(log);
          else if (log.id) response.ids.push(log.id);
          return response;
        }, { logs: [], ids: [] });
        record.ids_to_delete.push(...ids);
        record.logs = logs;
      }
      return {
        project_id: record.id,
        delete: record.ids_to_delete || [],
        items: record.logs.map((log) => _.pick(log, keys)),
      };
    },
    autoFill(event = {}) {
      if (event.keyCode ? event.keyCode !== 13 : false) return;
      this.Week.weekdays.forEach((weekday) => {
        const index = this.record.logs.findIndex((o) => weekday.isSame(o.created_at, 'day'));
        if (!this.Week.isWeekend(weekday)) {
          this.fillLog(weekday, index, this.fillHours);
        } else if (this.record.logs[index]) {
          this.fillLog(weekday, index, 0);
        }
      });
      this.fillHours = null;
      this.$toasted.success(`Week of ${this.record.name} filled`);
    },
    fillLog(day, index, hours) {
      const log = {
        hours,
        activities: (hours !== 0) ? `Automatic Filled at ${dayjs().format('YYYY-MM-DD hh:mm:ss')}` : '',
        created_at: day.format('YYYY-MM-DD'),
        bucket_estimation_id: null,
      };
      if (index >= 0) {
        this.record.logs[index] = _.assign(this.record.logs[index], log);
        return;
      }
      this.record.logs.push(log);
    },
  },
};
</script>
