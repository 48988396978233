<template>
  <button class="btn btn-company-primary" @click="copyLink()">
    <i v-if="is_copying" class="fas fa-sync fa-spin"></i>
    <i v-else class="fas fa-paper-plane"></i>
    Copy NPS
  </button>
</template>

<script type="text/javascript">

import { CREATE_SIGNED_URL } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';

export default {
  props: ['client_id', 'project_id'],
  data() {
    return {
      is_copying: false,
    };
  },
  methods: {
    copyLink() {
      this.is_copying = true;
      this.$apollo.mutate({
        mutation: CREATE_SIGNED_URL,
        variables: {
          client_id: this.client_id,
          project_id: this.project_id,
        },
      }).then(({ data }) => {
        navigator.clipboard.writeText(data.createNetPromoterScoreLink)
          .then(() => this.$toasted.success('NPS link was copied!'));
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).finally(() => {
        this.is_copying = false;
      });
    },
  },
};
</script>
