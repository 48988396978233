<template>
  <div class="card mb-2">
    <Header v-model="isCompacted" :index="index" />
    <div class="card-body" :hidden="isCompacted">
      <div class="card">
        <div class="d-lg-flex flex-grow">
          <div class="col-lg-4 p-0">
            <Selector v-model="currentDay" @toggle="toggleLog" :index="index" />
          </div>
          <div v-if="!isCompacted" class="col-lg-8 card rounded-0 px-3">
            <Form v-model="currentLog" :buckets="record.buckets" :placeholder="placeholder" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Form from './components/Form.vue';
import Header from './components/Header.vue';
import Selector from './components/Selector.vue';
import { Week, State } from '../State';

export default {
  props: { index: { required: true } },
  components: { Header, Selector, Form },
  data() {
    return {
      week: Week,
      state: State,
      isCompacted: true,
      currentDay: dayjs(),
      currentLog: {},
    };
  },
  mounted() { this.currentDay = this.week.get(dayjs().day()); },
  computed: {
    record() {
      return this.state.records[this.index];
    },
    placeholder() {
      return this.currentDay.placeholder;
    },
    stateChanged() {
      return {
        loading: this.record.isSaving || this.state.isLoading,
        weekends: this.record.has_weekends,
        compacted: this.isCompacted,
      };
    },
  },
  watch: {
    stateChanged(value) {
      if (value.loading || value.compacted) return;
      this.setValidLogDay();
    },
    currentDay() {
      if (this.isCompacted) return;
      this.setCurrentLogFor(this.currentDay);
    },
  },
  methods: {
    setValidLogDay() {
      if (this.isCompacted) return;
      this.currentDay = this.week.get(this.currentDay.day());
      if (!this.record.has_weekends && this.week.isWeekend(this.currentDay)) {
        this.currentDay = { 0: this.week.get(1), 6: this.week.get(5) }[this.currentDay.day()];
      } else this.setCurrentLogFor(this.currentDay);
    },
    setCurrentLogFor(weekday) {
      let index = this.record.logs.findIndex((o) => weekday.isSame(o.created_at, 'day'));
      if (index === -1) {
        this.record.logs.push({
          id: null,
          hours: 8,
          activities: '',
          bucket_estimation_id: null,
          created_at: weekday.format('YYYY-MM-DD'),
        });
        index = this.record.logs.length - 1;
      }
      this.currentLog = this.record.logs[index];
    },
    toggleLog() {
      const { hours, activities } = this.currentLog;
      this.currentLog.hours = (!hours && (hours !== '0') && !activities) ? 8 : 0;
      this.currentLog.activities = '';
      this.currentLog.bucket_estimation_id = null;
    },
  },
};
</script>
