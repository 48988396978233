<template>
  <div>
    <label for="item">
      <slot></slot>
    </label>
    <div class="form">
      <div class="row">
        <div class="mb-3 col-md-2">
          <label for="user">
            Person <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </label>
          <select id="user" ref="user" class="form-control form-select me-sm-2" v-model="currentProjectMember.user" :class="{'is-invalid': v$.currentProjectMember.user.$invalid}">
            <template v-if="usersDropDown">
              <option :key="user.id" v-for="user in usersDropDown" :value="user">
                {{user.name}}
              </option>
            </template>
          </select>
          <div v-for="error of v$.currentProjectMember.user.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label for="project_role">
            Role
            <i v-if="$apollo.loading" class="fas fa-spinner fa-spin"></i>
          </label>
          <select id="project_role" class="form-control form-select me-sm-2"
            v-model="currentProjectMember.project_role" :class="{'is-invalid': v$.currentProjectMember.project_role.$invalid}">
            <template v-if="projectRolesDropDown">
              <option :key="project_role.id" v-for="project_role in projectRolesDropDown" :value="project_role">
                {{project_role.name}}
              </option>
            </template>
          </select>
          <div v-for="error of v$.currentProjectMember.project_role.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label for="start_date">Start Date</label>
          <DatePicker :date="currentProjectMember.start_date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.currentProjectMember.start_date.$invalid}"
                      @change="currentProjectMember = { ...currentProjectMember, start_date: $event }"></DatePicker>
          <div v-for="error of v$.currentProjectMember.start_date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label for="contract_end_date">Contract End Date</label>
          <DatePicker :date="currentProjectMember.contract_end_date"
                      placeholder="Select Date"
                      :css_class="{'is-invalid': v$.currentProjectMember.contract_end_date.$invalid}"
                      @change="currentProjectMember = { ...currentProjectMember, contract_end_date: $event }"></DatePicker>
          <div v-for="error of v$.currentProjectMember.contract_end_date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-2">
          <label for="hours_per_week">Hours / Week</label>
          <input type="number" min="0" class="form-control rounded-0" placeholder="Expected hours per week"
            v-model="currentProjectMember.hours_per_week" :class="{'is-invalid': v$.currentProjectMember.hours_per_week.$invalid}"/>
          <div v-for="error of v$.currentProjectMember.hours_per_week.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="col-md-2 m-auto">
          <div class="form-check">
            <div class="form-check">
              <input id="is_manager" type="checkbox" class="form-check-input" v-model="currentProjectMember.is_manager">
              <label class="form-check-label m-auto" for="is_manager">Is Manager?</label>
            </div>
          </div>
        </div>
        <div class="mb-3 col-md-2 d-flex align-items-end">
          <button type="button" :disabled="v$.currentProjectMember.$invalid"  class="btn btn-success form-control" @click="addMember()">
            <i class="fas fa-plus"></i>
            Add
          </button>
        </div>
      </div>
    </div>
    <div class="row pt-2">
      <div class="col-md-12">
        <table class="table" v-if="selected">
          <tbody>
            <template v-for="(member, index) in selected">
              <Row :key="index" v-if="member.user && member.project_role" :member="member" :usersDropDown="usersDropDown"
                :projectRolesDropDown="projectRolesDropDown" @removeMember=removeMember @abandonProject="abandonProject"
                @extendContractEndDate="extendContractEndDate" @edit="edit"></Row>
            </template>
          </tbody>
        </table>
      </div>
    </div>
    <Modal :id="abandonModal.id" :ref="abandonModal.id" :hide_header="true">
      <template v-slot:body>
        <div class="mb-3">
          <label>Person left the project on:</label>
          <DatePicker :date="abandonModal.date"
                      placeholder="Select Date"
                      @change="abandonModal = { ...abandonModal, date: $event }"></DatePicker>
        </div>
      </template>
    </Modal>
    <Modal :id="extendContractModal.id" :ref="extendContractModal.id" :hide_header="true">
      <template v-slot:body>
        <div class="mb-3">
          <label>New contract end date:</label>
          <DatePicker :date="extendContractModal.date"
            placeholder="Select Date"
            @change="extendContractModal = { ...extendContractModal, date: $event }"></DatePicker>
        </div>
      </template>
    </Modal>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Validations from './Validations';
import Row from './Row.vue';
import { USERS, dropdownWithAlias } from '../../shared';
import { Modal } from '../shared';

export default {
  extends: Validations,
  components: { Modal, Row },
  apollo: {
    usersDropDown: {
      query: USERS,
      errorPolicy: 'all',
      variables() {
        return {
          orderBy: [{ column: 'name', order: 'ASC' }], is_active: true, is_external: false,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectRolesDropDown: {
      query: dropdownWithAlias('projectRolesDropDown'),
      errorPolicy: 'all',
      fetchPolicy: 'no-cache',
      variables() {
        return { type: 'ProjectRole', orderBy: [{ column: 'id', order: 'ASC' }] };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  props: ['selected', 'default_contract_end_date'],
  data() {
    return {
      abandonModal: {
        id: _.random(0, 100000),
        date: dayjs().format('YYYY-MM-DD'),
      },
      extendContractModal: {
        id: _.random(0, 100000),
        date: null,
      },
      currentProjectMember: {
        user: null,
        start_date: null,
        contract_end_date: this.default_contract_end_date,
        project_role: null,
        hours_per_week: 40,
        is_manager: false,
      },
    };
  },
  computed: {
    members() {
      return _.sortBy(this.selected, ['end_date']).reverse();
    },
  },
  methods: {
    edit(member) {
      const index = _.findIndex(this.selected, (m) => m.id === member.id);
      this.selected[index] = member;
      this.$forceUpdate();
    },
    addMember() {
      const hiringDate = dayjs(this.currentProjectMember.user.hiring_date, 'YYYY-MM-DD');
      if (hiringDate.isValid() && hiringDate.format('YYYY-MM-DD') > this.currentProjectMember.start_date) {
        this.$toasted.error('The user has a hire date greater than the start date');
        return;
      }
      this.selected.push({
        user: {
          id: this.currentProjectMember.user.id,
          name: this.currentProjectMember.user.name,
        },
        project_role: {
          id: this.currentProjectMember.project_role.id,
          name: this.currentProjectMember.project_role.name,
        },
        start_date: this.currentProjectMember.start_date,
        contract_end_date: this.currentProjectMember.contract_end_date,
        hours_per_week: this.currentProjectMember.hours_per_week,
        is_manager: this.currentProjectMember.is_manager,
      });
      this.currentProjectMember = {
        user: null,
        project_role: null,
        hours_per_week: 40,
        is_manager: false,
        start_date: this.currentProjectMember.start_date,
        contract_end_date: this.currentProjectMember.contract_end_date,
      };
      this.$refs.user.focus();
    },
    removeMember(projectMember) {
      projectMember.user = null;
      projectMember.project_role = null;
      this.$forceUpdate(); // removes from the interface the member when clicking on the trash button, without saving.
    },
    async extendContractEndDate(projectMember) {
      const accepted = await this.$refs[this.extendContractModal.id].show();
      const date = dayjs(this.extendContractModal.date, 'YYYY-MM-DD');
      if (!date.isValid()) {
        this.$toasted.error('The date is invalid');
        return;
      }
      if (accepted) {
        projectMember.contract_end_date = date.format('YYYY-MM-DD');
        // set the 'updated' property true in order to include the member in the upsert mutation
        projectMember.updated = true;
      }
    },
    async abandonProject(projectMember) {
      if (projectMember.end_date) {
        projectMember.end_date = null;
        // set the 'updated' property true in order to include the member in the upsert mutation
        projectMember.updated = true;
        this.$forceUpdate();
        return;
      }
      const accepted = await this.$refs[this.abandonModal.id].show();
      const date = dayjs(this.abandonModal.date, 'YYYY-MM-DD');
      if (!date.isValid() || date.format('YYYY-MM-DD') < projectMember.start_date) {
        // valid that the selected date is valid or greater than the start date
        this.$toasted.error('The date is invalid, the end date has to be greater than the start date');
        return;
      }
      if (accepted) {
        projectMember.end_date = date.format('YYYY-MM-DD');
        // set the 'updated' property true in order to include the member in the upsert mutation
        projectMember.updated = true;
        this.$forceUpdate();
      }
    },
  },
};
</script>
