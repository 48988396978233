<template>
<div :id="record.id" class="card">
  <div class="card-header d-flex">
    <div class="col p-0">
      <span v-if="!record.id">New Project Minute</span>
    </div>
    <div class="col text-end">
      <i class="fas fa-times" @click="$emit('close')"></i>
    </div>
  </div>
  <div class="card-body">
    <div class="mb-3">
      <label for="date">Date</label>
      <DatePicker :date="localRecord.date"
                  placeholder="Select Date"
                  :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                  @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
      <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <div v-if="!localRecord.id" class="mb-3">
      <label for="text">Templates:</label>
      <button class="btn btn-company-primary" @click="changeTemplate(0)">Weekly Internal Call</button>
      <button class="btn btn-company-primary" @click="changeTemplate(1)">Kickoff Call</button>
      <button class="btn btn-company-primary" @click="changeTemplate(2)">CMS Touch point</button>
    </div>
    <div class="mb-3">
      <label for="text">Notes</label>
      <input type="hidden" id="minute_name" v-model="localRecord.name">
      <trix-editor input="minute_name" @trix-change="localRecord.name = $event.target.value; $forceUpdate()"
      placeholder="Enter content"></trix-editor>
      <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
        <div class="text-danger">{{ error.$message }}</div>
      </div>
    </div>
    <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
      <i class="fas fa-save"></i>
      Save
    </button>
    <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
      <i class="fas fa-times"></i>
      Cancel
    </button>
  </div>
</div>
</template>
<script>

import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';
import { PROJECT_MEMBERS_QUERY } from '../projects/queries';
import { WEEKLY_INTERNAL_CALL, KICKOFF_CALL, CMS_TOUCH_POINT } from './templates';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
      templates: [WEEKLY_INTERNAL_CALL, KICKOFF_CALL, CMS_TOUCH_POINT],
    };
  },
  apollo: {
    project: {
      query: PROJECT_MEMBERS_QUERY,
      errorPolicy: 'all',
      variables() {
        return { id: this.record.project_id, active: true };
      },
      skip() {
        return ('id' in this.record);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    changeTemplate(index) {
      const editor = document.querySelector('trix-editor');
      const memberList = this.project.members.map((member) => `<li>${member.user.name}</li>`).join('');
      const managers = this.project.members.filter((m) => m.is_manager).map((manager) => `<li>${manager.user.name}</li>`).join('');
      const contacts = this.project.contacts.map((contact) => `<li>${contact.name}</li>`).join('');
      const template = this.templates[index].replaceAll('{{Attendees}}', `<ul>${memberList}</ul>`)
        .replaceAll('{{managers_and_pocs}}', `<ul>${managers}${contacts}</ul>`)
        .replaceAll('{{pocs}}', `<ul>${contacts}</ul>`);
      editor.value = template;
    },
    save() {
      const event = (this.localRecord.id) ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
