<template>
<div>
  <div class="container-fluid mt-2 ms-0 p-4">
    <div class="row">
      <section class="col-md-12">
        <h1 class="card-title">Public Privacy Policy</h1>
        <p>Effective date: <time datetime="2022-05-13">May 13, 2022</time></p>
        <p>This privacy policy sets out how this application uses and protects any information that you give
          when
          you use this website.</p>
      </section>
      <section class="col-md-12">
        <h2>The reduced version:</h2>
        <p>We use your personal information as this Privacy Statement describes. No matter where you are or we
          you live we provide the same standard of privacy protection to all our users.</p>
        <p>
          Of course, this sumary is not a complete statement of our privacy policy. So we encourage you to
          read on for more information.
        </p>
      </section>
      <section class="col-md-12">
                <h2>Summary</h2>
                <table class="table">
                    <thead>
                        <tr>
                            <th>Section</th>
                            <th>What you will find there</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row"><a href="#what-information-we-collect">What information we
                                    collect</a></th>
                            <td>
                                <p>We collect information from you when you register on our website, when you fill out a
                                    form, or when you
                                    interact with us through a social network.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row"><a href="#how-we-use-your-information">How your
                                    information is processed</a></th>
                            <td>
                                <p>We take appropriate security measures to prevent unauthorized access, disclosure,
                                    modification, or unauthorized
                                    destruction of the Data.</p>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row"><a
                                    href="#detailed-information-on-data-processing">Detailed information on the
                                    processing of your
                                    personal data</a></th>
                            <td>
                                <p>Personal data is collected and processed for specific purposes and services</p>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row"><a href="#information-california-users">Information for
                                    Users residing in
                                    California</a></th>
                            <td>
                                <p>This processing constitutes a sale based on the definition under the CCPA. In
                                    addition to the information in this
                                    clause, the User can find information regarding how to opt out of the sale in the
                                    section detailing the rights of
                                    Californian consumers.
                                </p>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row"><a class="fw-normal" href="#information-brazil-users">Information for Users
                                    residing in Brazil</a>
                            </th>
                            <td>
                                <p>
                                    The provisions contained in this section apply to all Users who reside in Brazil,
                                    according to the "Lei Geral de
                                    Proteção de Dados"</p>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </section>
            <section class="col-md-12">
                <h2 id="what-information-we-collect">What information we collect</h2>
                <h3>Types of data collected</h3>
                <p>
                    Among the types of Personal Data that this Application collects, by itself or through third parties,
                    there are: basic account information, usage
                    data, trackers.
                </p>
                <p>
                    <span class="fw-bold">"User Personal Information"</span> is any information about one of our Users
                    which could, alone or together
                    with other
                    information, personally identify them or otherwise be reasonably linked or connected with them.
                    Information such as a
                    username and password, an email address, a real name, an Internet protocol (IP) address, and a
                    photograph are examples
                    of “User Personal Information.”
                </p>
                <p>
                    User Personal Information does not include aggregated, non-personally identifying information that
                    does not identify a
                    User or cannot otherwise be reasonably linked or connected with them. We may use such aggregated,
                    non-personally
                    identifying information for research purposes and to operate, analyze, improve, and optimize our
                    Website and Service.

                </p>
                <p>
                    Complete details on each type of Personal Data collected are provided in the dedicated sections of
                    this privacy policy
                    or by specific explanation texts displayed prior to the Data collection.
                    Personal Data may be freely provided by the User, or, in case of Usage Data, collected automatically
                    when using this
                    Application.
                    Unless specified otherwise, all Data requested by this Application is mandatory and failure to
                    provide this Data may
                    make it impossible for this Application to provide its services.
                </p>
                <p>
                    In cases where this Application
                    specifically states
                    that some Data is not mandatory, Users are free not to communicate this Data without consequences to
                    the availability or
                    the functioning of the Service.
                </p>
                <p>
                    Users who are uncertain about which Personal Data is mandatory are welcome to contact the Owner.
                </p>
                <h3>Collection of Usage Data</h3>
                <p>
                    Any use of Cookies – or of other tracking tools – by this Application or by the owners of
                    third-party services used by
                    this Application serves the purpose of providing the Service required by the User, in addition to
                    any other purposes
                    described in the present document and in the Cookie Policy, if available.
                </p>
                <h4>Website interactions</h4>
                <p>
                    When you browse our applications, your browser automatically shares certain
                    information such as which
                    operating system and browser version you are using. We track that information, along with the pages
                    you are visiting,
                    page load timing, and which website referred you for statistical purposes like conversion rates and
                    to test new designs.
                    We sometimes track specific link clicks to help inform some design decisions. These web analytics
                    data are tied to your
                    IP address and user account if applicable and you are signed into our Services. We blind all of
                    these individual
                    identifiers after 30 days.
                </p>
                <h4>Anti-bot assessments</h4>
                <p>We use CAPTCHA services across our application to mitigate brute force logins and as a mean of spam
                    protection.
                    We have a legitimate interest in protecting our apps and the broader Internet community from
                    credential stuffing attacks
                    and spam. When you log into your accounts and fill specific forms, the CAPTCHA service evaluates
                    various
                    information (e.g IP address, how long the visitor has been on the app, mouse movements) to check
                    whether the data is
                    possibly filled out by an automated program instead of a human. We retain these data via our
                    subprocessor forever
                    because they are used for anti-spam mitigation.
                </p>
                <p>
                    Users are responsible for any third-party Personal Data obtained, published or shared through this
                    Application and
                    confirm that they have the third party's consent to provide the Data to the Owner.
                </p>
            </section>
            <section class="col-md-12">
                <h2 id="how-we-use-your-information">How we use your information</h2>
                <h3>Methods of processing</h3>
                <p>
                    The Owner takes appropriate security measures to prevent unauthorized access, disclosure,
                    modification, or unauthorized
                    destruction of the Data.
                </p>
                <p>
                    The Data processing is carried out using computers and/or IT enabled tools, following organizational
                    procedures and
                    modes strictly related to the purposes indicated. In addition to the Owner, in some cases, the Data
                    may be accessible to
                    certain types of persons in charge, involved with the operation of this Application (administration,
                    sales, marketing,
                    legal, system administration) or external parties (such as third-party technical service providers,
                    mail carriers,
                    hosting providers, IT companies, communications agencies) appointed, if necessary, as Data
                    Processors by the Owner. The
                    updated list of these parties may be requested from the Owner at any time.
                </p>
                <h3>Legal basis of processing</h3>
                <p>
                    The Owner may process Personal Data relating to Users if one of the following applies:
                </p>
                <ul>
                    <li>
                        Users have given their consent for one or more specific purposes. Note: Under some legislations
                        the
                        Owner may be allowed
                        to process Personal Data until the User objects to such processing (“opt-out”), without having
                        to
                        rely on consent or any
                        other of the following legal bases. This, however, does not apply, whenever the processing of
                        Personal Data is subject
                        to European data protection law;
                    </li>
                    <li>
                        provision of Data is necessary for the performance of an agreement with the User and/or for any
                        pre-contractual
                        obligations thereof;
                    </li>
                    <li>
                        processing is necessary for compliance with a legal obligation to which the Owner is subject;
                        processing is related to a task that is carried out in the public interest or in the exercise of
                        official authority
                        vested in the Owner;
                    </li>
                    <li>
                        processing is necessary for the purposes of the legitimate interests pursued by the Owner or by
                        a
                        third party.
                    </li>
                </ul>
                <p>
                    In any case, the Owner will gladly help to clarify the specific legal basis that applies to the
                    processing, and in
                    particular whether the provision of Personal Data is a statutory or contractual requirement, or a
                    requirement necessary
                    to enter into a contract.
                </p>
                <h3>Place</h3>
                <p>
                    The Data is processed at the Owner's operating offices and in any other places where the parties
                    involved in the
                    processing are located.
                </p>
                <p>
                    Depending on the User's location, data transfers may involve transferring the User's Data to a
                    country other than their
                    own. To find out more about the place of processing of such transferred Data, Users can check the
                    section containing
                    details about the processing of Personal Data.
                </p>
                <p>
                    Users are also entitled to learn about the legal basis of Data transfers to a country outside the
                    European Union or to
                    any international organization governed by public international law or set up by two or more
                    countries, such as the UN,
                    and about the security measures taken by the Owner to safeguard their Data.
                </p>
                <p>
                    If any such transfer takes place, Users can find out more by checking the relevant sections of this
                    document or inquire
                    with the Owner using the information provided in the contact section.
                </p>
                <h3>Retention time</h3>
                <p>
                    Personal Data shall be processed and stored for as long as required by the purpose they have been
                    collected for.
                </p>
                <p>
                    Therefore:
                </p>
                <ul>
                    <li>
                        Personal Data collected for purposes related to the performance of a contract between the Owner
                        and the User shall be
                        retained until such contract has been fully performed.
                    </li>
                    <li>
                        Personal Data collected for the purposes of the Owner’s legitimate interests shall be retained
                        as long as needed to
                        fulfill such purposes. Users may find specific information regarding the legitimate interests
                        pursued by the Owner
                        within the relevant sections of this document or by contacting the Owner.
                    </li>
                </ul>
                <p>
                    The Owner may be allowed to retain Personal Data for a longer period whenever the User has given
                    consent to such
                    processing, as long as such consent is not withdrawn. Furthermore, the Owner may be obliged to
                    retain Personal Data for
                    a longer period whenever required to do so for the performance of a legal obligation or upon order
                    of an authority.
                </p>
                <p>
                    Once the retention period expires, Personal Data shall be deleted. Therefore, the right of
                    access, the right to erasure,
                    the right to rectification and the right to data portability cannot be enforced after expiration
                    of the retention
                    period.
                </p>
                <h3>The purposes of processing</h3>
                <p>
                    The purposes of processing
                    The Data concerning the User is collected to allow the Owner to provide its Service, comply with its
                    legal obligations,
                    respond to enforcement requests, protect its rights and interests (or those of its Users or third
                    parties), detect any
                    malicious or fraudulent activity, as well as the following: Displaying content from external
                    platforms.
                </p>
                <p>
                    For specific information about the Personal Data used for each purpose, the User may refer to the
                    section “Detailed
                    information on the processing of Personal Data”.
                </p>
            </section>
            <section id="detailed-information-on-data-processing" class="col-md-12">
                <h2>Detailed information on the processing of Personal Data</h2>
                <p>
                    Personal Data is collected for the following purposes and using the following services:
                </p>
                <div id="personalDataUsage">
                    <div class="col-md-8 ps-0">
                        <h3 class="accordion-header" id="gFontsHeader">
                            <button class="btn btn-secondary" type="button" data-bs-toggle="collapse"
                                data-bs-target="#gFonts" aria-expanded="false" aria-controls="gFonts">
                                Displaying content from external platforms
                            </button>
                        </h3>
                        <div class="collapse" id="gFonts" aria-labelledby="gFontsHeader"
                            data-bs-parent="personalDataUsage">
                            <div class="card card-body">
                                <p>
                                    This type of service allows you to view content hosted on external platforms
                                    directly from the pages of this Application
                                    and interact with them.
                                </p>
                                <p>
                                    This type of service might still collect web traffic data for the pages where the
                                    service is installed, even when Users
                                    do not use it.
                                </p>
                                <p>
                                    <b>Google Fonts (Google LLC)</b>
                                </p>
                                <p>
                                    Google Fonts is a typeface visualization service provided by Google LLC that allows
                                    this Application to incorporate
                                    content of this kind on its pages.
                                </p>
                                <p>
                                    <span class="fw-bold">Personal Data processed:</span> Trackers; Usage Data.
                                </p>
                                <p>
                                    <strong>Place of processing:</strong> United States – <a
                                        href="https://developers.google.com/terms/api-services-user-data-policy">Privacy
                                        policy</a>
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section id="information-california-users" class="col-md-12">
                <h2>Category of personal information collected according to CCPA: internet information</h2>
                <p>
                    This processing constitutes a sale based on the definition under the CCPA. In addition to the
                    information in this
                    clause, the User can find information regarding how to opt out of the sale in the section detailing
                    the rights of
                    Californian consumers.
                </p>
                <h3>The rights of Users</h3>
                <p>
                    Users may exercise certain rights regarding their Personal Data processed by the Owner.
                </p>
                <p>
                    In particular, the User may:
                </p>
                <ul>
                    <li>
                        <strong>Withdraw their consent at any time.</strong> Users have the right to withdraw consent
                        where they have previously given their
                        consent to the processing of their Personal Data.
                    </li>
                    <li>
                        <strong>Object to processing of their Data.</strong> Users have the right to object to the
                        processing of their Data if the processing is carried out on a legal basis other
                        than consent. Further details are provided in the dedicated section below.
                    </li>
                    <li>
                        <strong>Request access to their Data.</strong> Users have the right to learn if Data is being
                        processed by the Owner, obtain disclosure regarding certain aspects of
                        the processing and obtain a copy of the Data undergoing processing.
                    </li>
                    <li>
                        <strong>Request correction of their Data.</strong> Users have the right to verify the accuracy
                        of their Data and ask for it to be updated or corrected.
                    </li>
                    <li>
                        <strong>Restrict the processing of their Data.</strong> Users have the right, under certain
                        circumstances, to restrict the processing of their Data. In this case, the Owner
                        will not process their Data for any purpose other than storing it.
                    </li>
                    <li>
                        <strong>Have their Data deleted.</strong> Users have the right, under certain circumstances, to
                        obtain the erasure of their Data from the Owner.
                    </li>
                    <li>
                        <strong>Receive their Data and have it transferred to another controller.</strong>Users have the
                        right to receive their Data in a structured, commonly used and machine readable format and, if
                        technically feasible, to have it transmitted to another controller without any hindrance. This
                        provision is applicable
                        provided that the Data is processed by automated means and that the processing is based on the
                        User's consent, on a
                        contract which the User is part of or on pre-contractual obligations thereof.
                    </li>
                    <li>
                        <strong>Lodge a complaint.</strong> Users have the right to bring a claim before their competent
                        data protection authority.
                    </li>
                </ul>
                <h3>
                    Details about the right to object to processing
                </h3>
                <p>
                    Where Personal Data is processed for a public interest, in the exercise of an official authority
                    vested in the Owner or
                    for the purposes of the legitimate interests pursued by the Owner, Users may object to such
                    processing by providing a
                    ground related to their particular situation to justify the objection.
                </p>
                <p>
                    Users must know that, however, should their Personal Data be processed for direct marketing
                    purposes, they can object to
                    that processing at any time without providing any justification. To learn, whether the Owner is
                    processing Personal Data
                    for direct marketing purposes, Users may refer to the relevant sections of this document.
                </p>
                <h3>
                    How to exercise these rights
                </h3>
                <p>
                    Any requests to exercise User rights can be directed to the Owner through the contact details
                    provided in this document.
                    These requests can be exercised free of charge and will be addressed by the Owner as early as
                    possible and always within
                    one month.
                </p>
                <h3>Additional information about User's Personal Data</h3>
                <p>
                    In addition to the information contained in this privacy policy, this Application may provide the
                    User with additional
                    and contextual information concerning particular Services or the collection and processing of
                    Personal Data upon
                    request.
                </p>
                <h4>System logs and maintenance</h4>
                <p>
                    For operation and maintenance purposes, this Application and any third-party services may collect
                    files that record
                    interaction with this Application (System logs) use other Personal Data (such as the IP Address) for
                    this purpose.
                </p>
                <h4>Information not contained in this policy</h4>
                <p>
                    More details concerning the collection or processing of Personal Data may be requested from the
                    Owner at any time.
                    Please see the contact information at the beginning of this document.
                </p>
                <h4>How “Do Not Track” requests are handled</h4>
                <p>
                    This Application does not support “Do Not Track” requests.
                    To determine whether any of the third-party services it uses honor the “Do Not Track” requests,
                    please read their
                    privacy policies.
                </p>
                <h4>Changes to this privacy policy</h4>
                <p>
                    The Owner reserves the right to make changes to this privacy policy at any time by notifying its
                    Users on this page and
                    possibly within this Application and/or - as far as technically and legally feasible - sending a
                    notice to Users via any
                    contact information available to the Owner. It is strongly recommended to check this page often,
                    referring to the date
                    of the last modification listed at the bottom.
                </p>
                <p>
                    Should the changes affect processing activities performed on the basis of the User’s consent, the
                    Owner shall collect
                    new consent from the User, where required.
                </p>
                <h3>Information for Californian consumers</h3>
                <p>
                    This part of the document integrates with and supplements the information contained in the rest of
                    the privacy policy
                    and is provided by the business running this Application and, if the case may be, its parent,
                    subsidiaries and
                    affiliates (for the purposes of this section referred to collectively as “we”, “us”, “our”).
                </p>
                <p>
                    The provisions contained in this section apply to all Users who are consumers residing in the state
                    of California,
                    United States of America, according to "The California Consumer Privacy Act of 2018" (Users are
                    referred to below,
                    simply as “you”, “your”, “yours”), and, for such consumers, these provisions supersede any other
                    possibly divergent or
                    conflicting provisions contained in the privacy policy.
                </p>
                <p>
                    This part of the document uses the term “personal information“ as it is defined in The California
                    Consumer Privacy Act
                    (CCPA).
                </p>
                <h4>Categories of personal information collected, disclosed or sold</h4>
                <p>
                    In this section we summarize the categories of personal information that we've collected, disclosed
                    or sold and the
                    purposes thereof.<strong> You can read about these activities in detail in the section titled
                        “Detailed
                        information on the
                        processing of Personal Data” within this document.</strong>
                </p>

                <h5>Information we collect: the categories of personal information we collect</h5>
                <p>
                    We have collected the following categories of personal information about you: internet information.
                </p>
                <p>
                    We will not collect additional categories of personal information without notifying you.
                </p>
                <h5>How we collect information: what are the sources of the personal information we collect?</h5>
                <p>
                    We collect the above mentioned categories of personal information, either directly or indirectly,
                    from you when you use
                    this Application.
                </p>
                <p>
                    For example, you directly provide your personal information when you submit requests via any forms
                    on this Application.
                    You also provide personal information indirectly when you navigate this Application, as personal
                    information about you
                    is automatically observed and collected. Finally, we may collect your personal information from
                    third parties that work
                    with us in connection with the Service or with the functioning of this Application and features
                    thereof.
                </p>
                <h5>How we use the information we collect: sharing and disclosing of your personal information with
                    third parties for a
                    business purpose</h5>
                <p>
                    We may disclose the personal information we collect about you to a third party for business
                    purposes. In this case, we
                    enter a written agreement with such third party that requires the recipient to both keep the
                    personal information
                    confidential and not use it for any purpose(s) other than those necessary for the performance of the
                    agreement.
                </p>
                <p>
                    We may also disclose your personal information to third parties when you explicitly ask or authorize
                    us to do so, in
                    order to provide you with our Service.
                </p>
                <p>
                    To find out more about the purposes of processing, please refer to the relevant section of this
                    document.
                </p>
                <h5>Sale of your personal information</h5>
                <p>
                    For our purposes, the word “sale” means any “selling, renting, releasing, disclosing, disseminating,
                    making available,
                    transferring or otherwise communicating orally, in writing, or by electronic means, a consumer's
                    personal information by
                    the business <strong>to another business or a third party, for monetary or other valuable
                        consideration”.</strong>
                </p>
                <p>
                    This means that, for example, a sale can happen whenever an application runs ads, or makes
                    statistical analyses on the
                    traffic or views, or simply because it uses tools such as social network plugins and the like.
                </p>
                <h5>Your right to opt out of the sale of personal information</h5>
                <p>
                    You have the right to opt out of the sale of your personal information. This means that whenever you
                    request us to stop
                    selling your data, we will abide by your request.
                    Such requests can be made freely, at any time, without submitting any verifiable request, simply by
                    following the
                    instructions below.
                </p>

                <h5>Instructions to opt out of the sale of personal information</h5>
                <p>
                    If you’d like to know more, or exercise your right to opt out in regard to all the sales carried out
                    by this
                    Application, both online and offline, you can contact us for further information using the contact
                    details provided in
                    this document.
                </p>
                <h5>What are the purposes for which we use your personal information?</h5>
                <p>
                    We may use your personal information to allow the operational functioning of this Application and
                    features thereof
                    (“business purposes”). In such cases, your personal information will be processed in a fashion
                    necessary and
                    proportionate to the business purpose for which it was collected, and strictly within the limits of
                    compatible
                    operational purposes.
                </p>
                <p>
                    We may also use your personal information for other reasons such as for commercial purposes (as
                    indicated within the
                    section “Detailed information on the processing of Personal Data” within this document), as well as
                    for complying with
                    the law and defending our rights before the competent authorities where our rights and interests are
                    threatened or we
                    suffer an actual damage.
                </p>
                <p>
                    We will not use your personal information for different, unrelated, or incompatible purposes without
                    notifying you.
                </p>
                <h4>Your California privacy rights and how to exercise them</h4>
                <h5>The right to know and to portability</h5>
                <p>
                    You have the right to request that we disclose to you:
                </p>
                <ul>
                    <li>
                        the categories and sources of the personal information that we collect about you, the purposes
                        for
                        which we use your
                        information and with whom such information is shared;
                    </li>
                    <li>
                        in case of sale of personal information or disclosure for a business purpose, two separate lists
                        where we disclose:
                    </li>
                    <ul>
                        <li>
                            for sales, the personal information categories purchased by each category of recipient;
                        </li>
                        <li>
                            and
                            for disclosures for a business purpose, the personal information categories obtained by each
                            category of recipient.
                        </li>
                    </ul>
                </ul>
                <p>
                    The disclosure described above will be limited to the personal information collected or used
                    over
                    the past 12 months.
                </p>
                <p>
                    If we deliver our response electronically, the information enclosed will be "portable", i.e.
                    delivered in an easily
                    usable format to enable you to transmit the information to another entity without hindrance –
                    provided that this is
                    technically feasible.
                </p>
                <h5>The right to request the deletion of your personal information</h5>
                <p>
                    You have the right to request that we delete any of your personal information, subject to
                    exceptions
                    set forth by the
                    law (such as, including but not limited to, where the information is used to identify and repair
                    errors on this
                    Application, to detect security incidents and protect against fraudulent or illegal activities,
                    to
                    exercise certain
                    rights etc.).
                </p>
                <p>
                    If no legal exception applies, as a result of exercising your right, we will delete your
                    personal
                    information and direct
                    any of our service providers to do so.
                </p>
                <h5>How to exercise your rights</h5>
                <p>
                    To exercise the rights described above, you need to submit your verifiable request to us by
                    contacting us via the
                    details provided in this document.
                </p>
                <p>
                    For us to respond to your request, it’s necessary that we know who you are. Therefore, you can
                    only
                    exercise the above
                    rights by making a verifiable request which must:
                </p>
                <ul>
                    <li>
                        provide sufficient information that allows us to reasonably verify you are the person about whom
                        we
                        collected personal
                        information or an authorized representative;
                    </li>
                    <li>
                        describe your request with sufficient detail that allows us to properly understand, evaluate,
                        and
                        respond to it.
                    </li>
                </ul>
                <p>
                    We will not respond to any request if we are unable to verify your identity and therefore
                    confirm
                    the personal
                    information in our possession actually relates to you.
                </p>
                <p>
                    If you cannot personally submit a verifiable request, you can authorize a person registered with
                    the
                    California
                    Secretary of State to act on your behalf.
                </p>
                <p>
                    If you are an adult, you can make a verifiable request on behalf of a minor under your parental
                    authority.
                </p>
                <p>
                    You can submit a maximum number of 2 requests over a period of 12 months.
                </p>
                <h5>How and when we are expected to handle your request</h5>
                <p>
                    We will confirm receipt of your verifiable request within 10 days and provide information about
                    how
                    we will process your
                    request.
                </p>
                <p>
                    We will respond to your request within 45 days of its receipt. Should we need more time, we will
                    explain to you the
                    reasons why, and how much more time we need. In this regard, please note that we may take up to
                    90
                    days to fulfill your
                    request.
                </p>
                <p>
                    Our disclosure(s) will cover the preceding 12 month period.
                </p>
                <p>
                    Should we deny your request, we will explain you the reasons behind our denial.
                </p>
                <p>
                    We do not charge a fee to process or respond to your verifiable request unless such request is
                    manifestly unfounded or
                    excessive. In such cases, we may charge a reasonable fee, or refuse to act on the request. In
                    either
                    case, we will
                    communicate our choices and explain the reasons behind it.
                </p>
            </section>
            <section id="information-brazil-users" class="col-md-12">
                <h2>Information for Users residing in Brazil</h2>
                <p>
                    This part of the document integrates with and supplements the information contained in the rest of
                    the
                    privacy policy
                    and is provided by the entity running this Application and, if the case may be, its parent,
                    subsidiaries
                    and affiliates
                    (for the purposes of this section referred to collectively as “we”, “us”, “our”).
                    The provisions contained in this section apply to all Users who reside in Brazil, according to the
                    "Lei
                    Geral de
                    Proteção de Dados" (Users are referred to below, simply as “you”, “your”, “yours”). For such Users,
                    these provisions
                    supersede any other possibly divergent or conflicting provisions contained in the privacy policy.
                    This part of the document uses the term “personal information“ as it is defined in the Lei Geral de
                    Proteção de Dados
                    <strong>(LGPD).</strong>
                </p>
                <h3>The grounds on which we process your personal information</h3>
                <p>
                    We can process your personal information solely if we have a legal basis for such processing. Legal
                    bases are as
                    follows:
                </p>
                <ul>
                    <li>
                        your consent to the relevant processing activities;
                    </li>
                    <li>
                        compliance with a legal or regulatory obligation that lies with us;
                    </li>
                    <li>
                        the carrying out of public policies provided in laws or regulations or based on contracts,
                        agreements
                        and similar legal
                        instruments;
                    </li>
                    <li>
                        studies conducted by research entities, preferably carried out on anonymized personal
                        information;
                    </li>
                    <li>
                        the carrying out of a contract and its preliminary procedures, in cases where you are a party to
                        said
                        contract;
                    </li>
                    <li>
                        the exercising of our rights in judicial, administrative or arbitration procedures;
                        protection or physical safety of yourself or a third party;
                    </li>
                    <li>
                        the protection of health – in procedures carried out by health entities or professionals;
                        our legitimate interests, provided that your fundamental rights and liberties do not prevail
                        over
                        such
                        interests;
                    </li>
                    <li>
                        and
                        credit protection.
                    </li>
                </ul>
                <p>
                    To find out more about the legal bases, you can contact us at any time using the contact details
                    provided in this
                    document.
                </p>
                <h3>Categories of personal information processed</h3>
                <p>
                    To find out what categories of your personal information are processed, you can read the section
                    titled
                    “Detailed
                    information on the processing of Personal Data” within this document.
                </p>
                <h3>Why we process your personal information</h3>
                <p>
                    To find out why we process your personal information, you can read the sections titled “Detailed
                    information on the
                    processing of Personal Data” and “The purposes of processing” within this document.
                </p>
                <h3>Your Brazilian privacy rights, how to file a request and our response to your requests</h3>
                <h4>Your Brazilian privacy rights</h4>
                <p>
                    You have the right to:
                </p>
                <ul>
                    <li>
                        obtain confirmation of the existence of processing activities on your personal information;
                        access to your personal information;
                    </li>
                    <li>
                        have incomplete, inaccurate or outdated personal information rectified;
                    </li>
                    <li>
                        obtain the anonymization, blocking or elimination of your unnecessary or excessive personal
                        information,
                        or of
                        information that is not being processed in compliance with the LGPD;
                    </li>
                    <li>
                        obtain information on the possibility to provide or deny your consent and the consequences
                        thereof;
                    </li>
                    <li>
                        obtain information about the third parties with whom we share your personal information;
                        obtain, upon your express request, the portability of your personal information (except for
                        anonymized
                        information) to
                        another service or product provider, provided that our commercial and industrial secrets are
                        safeguarded;
                    </li>
                    <li>
                        obtain the deletion of your personal information being processed if the processing was based
                        upon
                        your
                        consent, unless
                        one or more exceptions provided for in art. 16 of the LGPD apply;
                    </li>
                    <li>
                        revoke your consent at any time;
                    </li>
                    <li>
                        lodge a complaint related to your personal information with the ANPD (the National Data
                        Protection
                        Authority) or with
                        consumer protection bodies;
                    </li>
                    <li>
                        oppose a processing activity in cases where the processing is not carried out in compliance with
                        the
                        provisions of the
                        law;
                    </li>
                    <li>
                        request clear and adequate information regarding the criteria and procedures used for an
                        automated
                        decision;
                    </li>
                    <li>
                        and
                        request the review of decisions made solely on the basis of the automated processing of your
                        personal
                        information, which
                        affect your interests. These include decisions to define your personal, professional, consumer
                        and
                        credit profile, or
                        aspects of your personality.
                    </li>
                </ul>
                <p>
                    You will never be discriminated against, or otherwise suffer any sort of detriment, if you
                    exercise
                    your
                    rights.
                </p>
                <h4>How to file your request</h4>
                <p>
                    You can file your express request to exercise your rights free from any charge, at any time, by
                    using
                    the contact
                    details provided in this document, or via your legal representative.
                </p>
                <h4>How and when we will respond to your request</h4>
                <p>
                    We will strive to promptly respond to your requests.
                </p>
                <p>
                    In any case, should it be impossible for us to do so, we’ll make sure to communicate to you the
                    factual
                    or legal reasons
                    that prevent us from immediately, or otherwise ever, complying with your requests. In cases
                    where we
                    are
                    not processing
                    your personal information, we will indicate to you the physical or legal person to whom you
                    should
                    address your
                    requests, if we are in the position to do so.
                </p>
                <p>
                    In the event that you file an access or personal information processing confirmation request,
                    please
                    make sure that you
                    specify whether you’d like your personal information to be delivered in electronic or printed
                    form.
                    You will also need to let us know whether you want us to answer your request immediately, in
                    which
                    case
                    we will answer
                    in a simplified fashion, or if you need a complete disclosure instead.
                    In the latter case, we’ll respond within 15 days from the time of your request, providing you
                    with
                    all
                    the information
                    on the origin of your personal information, confirmation on whether or not records exist, any
                    criteria
                    used for the
                    processing and the purposes of the processing, while safeguarding our commercial and industrial
                    secrets.
                </p>
                <p>
                    In the event that you file a rectification, deletion, anonymization or personal information
                    blocking
                    request, we will
                    make sure to immediately communicate your request to other parties with whom we have shared your
                    personal information in
                    order to enable such third parties to also comply with your request – except in cases where such
                    communication is proven
                    impossible or involves disproportionate effort on our side.
                </p>
                <h3>Transfer of personal information outside of Brazil permitted by the law</h3>
                <p>
                    We are allowed to transfer your personal information outside of the Brazilian territory in the
                    following
                    cases:
                </p>
                <ul>
                    <li>
                        when the transfer is necessary for international legal cooperation between public intelligence,
                        investigation and
                        prosecution bodies, according to the legal means provided by the international law;
                    </li>
                    <li>
                        when the transfer is necessary to protect your life or physical security or those of a third
                        party;
                    </li>
                    <li>
                        when the transfer is authorized by the ANPD;
                    </li>
                    <li>
                        when the transfer results from a commitment undertaken in an international cooperation
                        agreement;
                    </li>
                    <li>
                        when the transfer is necessary for the execution of a public policy or legal attribution of
                        public
                        service;
                    </li>
                    <li>
                        when the transfer is necessary for compliance with a legal or regulatory obligation, the
                        carrying
                        out of
                        a contract or
                        preliminary procedures related to a contract, or the regular exercise of rights in judicial,
                        administrative or
                        arbitration procedures.
                    </li>
                </ul>
            </section>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: 'PrivacyPolicy',
};
</script>
