export default class RequestBuilder {
  static build(project) {
    const keys = [
      'name', 'description', 'internal_description', 'is_confidential',
      'is_internal', 'type', 'state', 'start_date', 'end_date', 'client_id',
      'status', 'estimation_id', 'closed_at', 'only_my_projects', 'budget',
    ];
    const requestBody = _.pick(project, keys);
    requestBody.technologies = {
      sync: (project.technologies || []).filter((tech) => tech.id).map((tech) => tech.id),
      create: (project.technologies || []).map((tech) => tech).filter((tech) => !tech.id),
    };
    requestBody.tags = {
      sync: (project.tags || []).filter((tag) => tag.id).map((tag) => tag.id),
      create: (project.tags || []).map((tag) => tag).filter((tag) => !tag.id),
    };
    requestBody.contacts = {
      delete: (project.contacts || []).filter((contact) => contact.deleted).map((contact) => contact.id),
      upsert: (project.contacts || []).filter((contact) => !contact.id)
        .map((contact) => ({
          name: contact.name,
          position: contact.position,
          email: contact.email,
          phone: contact.phone,
          notes: contact.notes,
        }))
        .concat(
          (project.contacts || []).filter((contact) => contact.id)
            .map((contact) => ({
              id: contact.id,
              name: contact.name,
              position: contact.position,
              email: contact.email,
              phone: contact.phone,
              notes: contact.notes,
            })),
        ),
    };
    requestBody.vertical_markets = {
      sync: (project.vertical_markets || []).filter((verticalMarket) => verticalMarket.id)
        .map((verticalMarket) => verticalMarket.id),
      create: (project.vertical_markets || []).map((verticalMarket) => verticalMarket)
        .filter((verticalMarket) => !verticalMarket.id),
    };
    requestBody.members = {
      delete: (project.members || []).filter((member) => !member.user && !member.project_role)
        .map((member) => member.id),
      upsert: (project.members || []).filter((member) => !member.id)
        .map((member) => ({
          user_id: member.user.id,
          project_role_id: member.project_role.id,
          start_date: member.start_date,
          end_date: member.end_date,
          contract_end_date: member.contract_end_date,
          hours_per_week: parseFloat(member.hours_per_week),
          is_manager: member.is_manager,
        }))
        .concat(
          (project.members || []).filter((member) => member.updated)
            .map((member) => ({
              id: member.id,
              user_id: member.user.id,
              project_role_id: member.project_role.id,
              start_date: member.start_date,
              end_date: member.end_date,
              contract_end_date: member.contract_end_date,
              hours_per_week: parseFloat(member.hours_per_week),
              is_manager: member.is_manager,
            })),
        ),
    };
    if (requestBody.budget) {
      requestBody.budget = parseFloat(requestBody.budget);
    }
    return requestBody;
  }
}
