<template>
  <div>
    <Form v-if="record" v-bind:record="record"
                                @close="record = null"
                                @insert="insert"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="col-md-3 m-auto p-0">
          <ol class="breadcrumb p-0 m-auto">
            <li class="breadcrumb-item">
              <router-link to="/home">
                <i class="fas fa-home"></i>
              </router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link to="/projects">Projects</router-link>
            </li>
            <li class="breadcrumb-item active fw-bold" aria-current="page">
              <router-link :to="`/projects?name=${project?.name}`">{{ project?.name }}</router-link>
            </li>
            <li class="breadcrumb-item fw-bold" aria-current="page">
              Minutes
            </li>
          </ol>
        </div>
        <div class="col-md-7 m-auto p-0">
          <Search
            :search.sync="search"
            :inputs="['name']"
          ></Search>
        </div>
        <div class="col-md-2 text-end">
          <button type="button" class="btn btn-company-primary" @click="newMinute()" v-if="$can('CreateProjectMinute')">
            <i class="fas fa-plus"></i>
            New
          </button>
        </div>
      </div>
    </nav>
    <div class="row" v-if="projectMinutes">
      <Card :key="index" v-for="(record, index) in projectMinutes.data" :record="record" @remove="remove"></Card>
      <Empty v-if="!projectMinutes.data.length" icon="fas fa-project-diagram"  :can_create="true" @callCreate="newMinute"/>
    </div>
    <SkeletonCards v-if="projectMinutes && projectMinutes.paginatorInfo.hasMorePages" :cards=2 :executeScroll="projectMinutes != undefined" @showMore="showMore('projectMinutes')"></SkeletonCards>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import { gql } from 'apollo-boost';
import PROJECT_MINUTE_QUERY from './queries';
import { CREATE_PROJECT_MINUTE, DELETE_PROJECT_MINUTE } from './mutations';
import { Search, SkeletonCards } from '../shared';
import Form from './Form.vue';
import Card from './Card.vue';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../shared';

export default {
  extends: PaginationHandler,
  components: {
    Form, Card, SkeletonCards, Search,
  },
  apollo: {
    project: {
      query: gql`query($id: ID!) { project(id: $id) { id name }}`,
      errorPolicy: 'all',
      variables() {
        return { id: this.$route.params.id };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    projectMinutes: {
      query: PROJECT_MINUTE_QUERY,
      errorPolicy: 'all',
      variables() {
        return { page: 1, project_id: this.$route.params.id, ...SearchBuilder.build(this.search) };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  data() {
    return {
      search: {},
      record: null,
    };
  },
  methods: {
    queryVariables() {
      return { project_id: parseInt(this.$route.params.id, 10), ...SearchBuilder.build(this.search) };
    },
    remove(record) {
      this.$apollo.mutate({
        mutation: DELETE_PROJECT_MINUTE,
        variables: {
          id: parseInt(record.id, 10),
        },
      }).catch((error) => {
        this.$toasted.error(`Unable to delete record: ${error.message}`);
      }).then(() => {
        this.projectMinutes.data.splice(this.projectMinutes.data.indexOf(record), 1);
      });
    },
    newMinute() {
      this.record = {
        project_id: parseInt(this.$route.params.id, 10),
        date: dayjs().format('YYYY-MM-DD'),
        name: '',
      };
    },
    insert(minute) {
      // We save the user input in case of an error
      minute.project_id = parseInt(this.$route.params.id, 10);
      const newMinute = minute;
      this.$apollo.mutate({
        mutation: CREATE_PROJECT_MINUTE,
        variables: {
          projectMinute: minute,
        },
      }).then((response) => {
        this.record = null;
        this.projectMinutes.data.unshift(response.data.createProjectMinute);
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.record = newMinute;
      });
    },
  },
};
</script>
