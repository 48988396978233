<template>
  <div :id="record.id" class="col-md-3 pb-2">
    <Form
      v-if="is_editting"
      v-bind:record="record"
      @close="is_editting = false"
      @update="update"
    ></Form>
    <div v-if="!is_editting" class="card h-100">
      <div class="card-body pb-0">
        <h5 class="card-title">
          {{ record.name }}
        </h5>
      </div>
      <div class="card-body pt-0 pb-0" v-if="$can('UpdateUser')">
        <div class="row">
          <div class="col-md-12">
            <strong>Permissions:</strong>
          </div>
          <div class="col-md-12">
            <span
              :key="permission.id"
              v-for="permission in record.permissions"
              class="badge bg-company-secondary me-1"
              >{{ permission.name }}</span>
          </div>
        </div>
      </div>
      <div class="card-body">
        <button
          v-if="!confirmation && $can('UpdateBots')"
          class="btn btn-company-primary"
          @click="is_editting = true">
          <i class="fas fa-pen"></i>
          Edit
        </button>
        <Confirmation v-if="!is_editting && $can('DeleteBots')" v-model="confirmation"
          class="d-inline"
          @accepted="remove()"/>
        <button
          v-if="!confirmation && $can('CopyToken')"
          class="btn btn-company-primary"
          @click="copyToken">
          <i class="fas fa-copy"></i>
          Token!
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import { UPDATE_BOT } from './mutations';
import Form from './Form.vue';
import RequestBuilder from './RequestBuilder';
import ErrorHandler from '../../shared/ErrorHandler';
import { Confirmation } from '../shared';

export default {
  components: { Form, Confirmation },
  props: ['record'],
  data() {
    return {
      is_editting: false,
      confirmation: false,
    };
  },
  methods: {
    update(record) {
      this.$apollo.mutate({
        mutation: UPDATE_BOT,
        variables: {
          id: record.id,
          bot: RequestBuilder.build(record),
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).then(() => {
        this.is_editting = false;
        this.$emit('refresh');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
    remove() {
      this.$emit('remove', this.record);
      this.is_editting = false;
      this.confirmation = false;
    },
    copyToken() {
      navigator.clipboard.writeText(this.record.api_token)
        .then(() => this.$toasted.success('Token was copied!'));
    },
  },
};
</script>
