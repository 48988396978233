<template>
  <div>
    <Form v-if="selectedRecord" @insert="insert" @close="selectedRecord = null"></Form>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">My Vacation Request</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto">
              <Search
                :search.sync="search"
                :inputs="['dateRanges']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end pt-2">
              <button type="button" class="btn btn-company-primary" @click="selectedRecord = {}">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-md-12 mt-2 ms-3">
              <div class="badge bg-company-third-ligth" v-if="me">
                <i class="fa-solid fa-umbrella-beach"></i> {{me.available_pto_days}} {{ me.available_pto_days === 1 ? 'day' : 'days' }} available
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="my_vacation_request">
      <div class="col-md-12">
        <table class="table">
          <tr>
            <th>Person</th>
            <th>Total days</th>
            <th>Requested dates</th>
            <th>Status</th>
          </tr>
          <tr :key="row.id" v-for="row in my_vacation_request.data">
            <td>{{row.user.name}}</td>
            <td>{{row.total_days}}</td>
            <td>
              {{formattedDates(row.dates)}}
            </td>
            <td class="text-capitalize">{{row.status}}</td>
          </tr>
          <SkeletonRow v-if="my_vacation_request && my_vacation_request.paginatorInfo.hasMorePages" :colspan=6 :executeScroll="my_vacation_request != undefined" @showMore="showMore('my_vacation_request')"/>
        </table>
      </div>
    </div>
    <Empty v-if="!my_vacation_request?.data.length" icon="fas fa-plane-departure" :can_create="true" @callCreate="selectedRecord = {}"/>
  </div>
</template>
<script>

import dayjs from 'dayjs';
import { MY_VACATION_REQUEST, AVAILABLE_PTO_DAYS } from './queries';
import { SearchBuilder, ErrorHandler, PaginationHandler } from '../../../shared';
import Form from './Form.vue';
import { SkeletonRow, Search, SpinnerRouterLink } from '../../shared';
import CREATE_PAID_TIME_OFF_REQUEST from './mutation';

export default {
  extends: PaginationHandler,
  components: {
    SkeletonRow, Form, Search, SpinnerRouterLink,
  },
  data() {
    return {
      search: {},
      selectedRecord: null,
      orderBy: { column: 'id', order: 'DESC' },
    };
  },
  apollo: {
    my_vacation_request: {
      query: MY_VACATION_REQUEST,
      errorPolicy: 'all',
      variables() {
        return { page: 1, orderBy: [this.orderBy], ...SearchBuilder.build(this.search) };
      },
      error(error) {
        ErrorHandler.handle(error, this);
      },
    },
    me: AVAILABLE_PTO_DAYS,
  },
  methods: {
    queryVariables() {
      return { orderBy: [{ column: 'id', order: 'DESC' }], ...this.search };
    },
    formattedDates(dates) {
      return (_.map(dates, (d) => dayjs(d.date).format('D MMM YYYY'))).join(', ');
    },
    refresh() {
      this.$apollo.queries.my_vacation_request.refetch();
    },
    insert(paidTimeOffRequest) {
      // We save the user input in case of an error
      const newRequest = paidTimeOffRequest;
      this.selectedRecord = null;
      this.$apollo.mutate({
        mutation: CREATE_PAID_TIME_OFF_REQUEST,
        variables: {
          paid_time_off_request: paidTimeOffRequest,
        },
      }).then(() => {
        this.refresh();
        this.$toasted.success('PTO request was created!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
        // We restore the initial user input
        this.selectedRecord = newRequest;
      });
    },
  },
};
</script>
