<template>
  <div class="position-relative w-100">
    <input class="form-control position-absolute top-0 start-0" :class="css_class" type="text" v-model="displayFormat" @click="$refs.nativePicker.showPicker()" :placeholder="placeholder" readonly>
    <input class="invisible" ref="nativePicker" type="date" v-model="localRecord" @change="$emit('change', localRecord)">
  </div>
</template>
<script>
import dayjs from 'dayjs';

export default {
  props: ['date', 'placeholder', 'css_class'],
  data() {
    return {
      localRecord: structuredClone(this.date),
    };
  },
  computed: {
    displayFormat() {
      if (!this.localRecord) { return ''; }
      return dayjs(this.localRecord).format('D MMM YYYY');
    },
  },
};
</script>
