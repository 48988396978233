<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
                <li class="breadcrumb-item active fw-bold" aria-current="page">
                  Costs Per Resource
                </li>
            </ul>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-12 col-sm-6 m-auto">
              <Search
                :search.sync="search"
                :inputs="['users']"
              ></Search>
            </div>
            <div class="col-12 col-sm-6 order-last text-end mt-2 pt-2">
              <button type="button" class="btn btn-company-primary" @click="toggleForm">
                <i class="fas fa-plus"></i>
                New
              </button>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <template v-if="user && search.user_id">
      <div class="text-nowrap overflow-auto">
        <table class="table table-sm">
          <thead>
            <tr>
              <td v-if="user && user.name" class="custom-header-orange">
                <b>Name</b>
              </td>
              <td class="custom-header-orange" v-if="user && user.name" v-bind:colspan="user && user.job_title && user.job_title.internal_description? '1':'5' ">
                {{user.name}}
              </td>
              <td class="custom-header-orange" v-if="user && user.job_title && user.job_title.internal_description" >
                <b>Job Title</b>
              </td>
              <td  class="custom-header-orange" v-if="user && user.job_title && user.job_title.internal_description" colspan="2">
                {{user.job_title.internal_description}}
              </td>
            </tr>
            <tr>
              <th>#</th>
              <th>Cost</th>
              <th>Date</th>
              <th>Time</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="inserting">
              <td>ID</td>
              <td>
                <input
                    v-focus
                    v-model="localRecord.cost"
                    type="text"
                    class="form-control w-auto"
                    placeholder="Cost in $"
                    aria-label="Cost"
                    required>
                <div v-for="error of v$.localRecord.cost.$silentErrors" :key="error.$uid">
                  <div class="text-danger">{{ error.$message }}</div>
                </div>
              </td>
              <td>
                <div class="mb-2">
                  <DatePicker :date="localRecord.date"
                              placeholder="Select Date"
                              :css_class="{'is-invalid': v$.localRecord.date.$invalid}"
                              @change="localRecord = { ...localRecord, date: $event }"></DatePicker>
                </div>
                <div v-for="error of v$.localRecord.date.$silentErrors" :key="error.$uid">
                  <div class="text-danger">{{ error.$message }}</div>
                </div>
              </td>
              <td></td>
              <td>
                <button class="btn btn-company-primary" @click="toggleForm">Cancel</button>
                <button :disabled="v$.localRecord.$invalid" class="btn btn-company-secondary" @click="insertNewCostPerUser">Save</button>
              </td>
            </tr>
            <Row v-for="(userCost, index) in costsPerUser"
              :userCost="userCost"
              :index="index"
              :key="userCost.id"
              @costUpdated="costUpdated"
              @cancel="cancel"></Row>
          </tbody>
        </table>
      </div>
    </template>
    <Empty v-if="!costsPerUser" :can_create="true" icon="fas fa-dollar-sign" @callCreate="toggleForm"/>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Validations from './Validations';
import { USER, COST_PER_USER } from './queries';
import { CREATE_COST_PER_USER, UPDATE_COST_PER_USER } from './mutations';
import ErrorHandler from '../../shared/ErrorHandler';
import Row from './Row.vue';
import { Search } from '../shared';

export default {
  extends: Validations,
  data() {
    return {
      inserting: false,
      localRecord: {
        cost: '',
        date: null,
      },
      search: { user_id: this.$route.query.user_id ?? null },
    };
  },
  components: { Search, Row },
  apollo: {
    user: {
      errorPolicy: 'all',
      query: USER,
      variables() {
        return {
          id: this.search.user_id,
        };
      },
      skip() {
        return !this.search.user_id;
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    costsPerUser: {
      errorPolicy: 'all',
      query: COST_PER_USER,
      update(data) {
        if (!data.costsPerUser) return data;
        const { costsPerUser } = data;
        costsPerUser.forEach((cost) => {
          const timeTemp = dayjs(cost.date);
          cost.time = Math.abs(timeTemp.diff(dayjs(Date.now()), 'days'));
        });
        return data.costsPerUser;
      },
      variables() {
        return {
          user_id: this.search.user_id,
          orderBy: [{ column: 'created_at', order: 'DESC' }],
        };
      },
      skip() {
        return !this.search.user_id;
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  methods: {
    toggleForm() {
      this.inserting = !this.inserting;
      if (this.inserting) {
        this.localRecord = {
          cost: '',
          date: null,
        };
      }
    },
    insertNewCostPerUser() {
      this.inserting = !this.inserting;
      this.$apollo.mutate({
        mutation: CREATE_COST_PER_USER,
        variables: {
          input: {
            cost: Number(this.localRecord.cost),
            date: this.localRecord.date,
            user_id: this.user.id,
          },
        },
        update: (store, { data: { createCostPerUser } }) => {
          const data = store.readQuery({ query: COST_PER_USER, variables: { user_id: this.search.user_id, orderBy: [{ column: 'created_at', order: 'DESC' }] } });
          data.costsPerUser.unshift(createCostPerUser);
          store.writeQuery({ query: COST_PER_USER, variables: { user_id: this.search.user_id, orderBy: [{ column: 'created_at', order: 'DESC' }] }, data });
        },
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
      this.localRecord = { cost: '', date: null };
    },
    cancel(userCost) {
      const updatedUserCost = _.find(this.costsPerUser, (cpu) => cpu.id === userCost.id);
      userCost.cost = updatedUserCost.cost;
      userCost.date = updatedUserCost.date;
    },
    costUpdated(userCost) {
      const updatedUserCost = _.find(this.costsPerUser, (cpu) => cpu.id === userCost.id);
      updatedUserCost.cost = Number(userCost.cost);
      updatedUserCost.date = userCost.date;

      this.$apollo.mutate({
        mutation: UPDATE_COST_PER_USER,
        variables: {
          id: userCost.id,
          input: _.pick(updatedUserCost, ['cost', 'date']),
        },
      }).then(() => {
        this.$toasted.success('New cost was saved!');
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      });
    },
  },
};
</script>
