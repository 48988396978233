import Vue from 'vue';
import dayjs from 'dayjs';
import relativeTime from 'dayjs/plugin/relativeTime';

dayjs.extend(relativeTime);

Vue.prototype.$displayDate = ((value) => {
  if (!value) {
    return 'N/A';
  }
  return dayjs(value, 'YYYY-MM-DD').format('MMM D, YYYY');
});
