import { useVuelidate } from '@vuelidate/core';
import {
  required, minValue, maxLength, requiredIf, minLength,
} from '@vuelidate/validators';

export default {
  setup: () => ({ v$: useVuelidate() }),
  validations() {
    return {
      localRecord: {
        name: { required, maxLengthValue: maxLength(255) },
        description: { required, minLengthValue: minLength(7) },
        type: { required },
        state: { required, maxLengthValue: maxLength(255) },
        start_date: { required },
        end_date: { required },
        closed_at: {
          requiredIfFuction: requiredIf(
            () => ['Cancelled', 'Completed'].includes(this.localRecord.state),
          ),
        },
        client_id: { required },
        status: { required },
        budget: { required, minValueValue: minValue(0) },
      },
    };
  },
};
