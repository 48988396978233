<template>
  <div class="mb-5 card">
    <div class="card-header d-flex">
      <div class="col p-0">
        <span v-if="!record.id">New Equipment</span>
        <span v-if="record.id">Editing Equipment</span>
      </div>
      <div class="col text-end">
        <i class="fas fa-times" @click="$emit('close')"></i>
      </div>
    </div>
    <div class="card-body d-grid">
      <div class="row">
        <div class="mb-3 col-md-4">
          <label for="acquired_date">Acquired at</label>
          <DatePicker :date="localRecord.acquired_date"
                      placeholder="Select Acquired Date"
                      :css_class="{'is-invalid': v$.localRecord.acquired_date.$invalid}"
                      @change="localRecord = { ...localRecord, acquired_date: $event }"></DatePicker>
          <div v-for="error of v$.localRecord.acquired_date.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="type">Type</label>
          <select id="type" :class="{'is-invalid': v$.localRecord.type.$invalid}" class="form-control" v-model="localRecord.type">
            <option>Cables</option>
            <option>Camera</option>
            <option>Cellphone</option>
            <option>Charger</option>
            <option>Desktop</option>
            <option>Furniture</option>
            <option>Laptop</option>
            <option>MFA Device</option>
            <option>Monitor</option>
            <option>Other</option>
            <option>Peripheral</option>
            <option>Tablet</option>
            <option>Tools</option>
          </select>
          <div v-for="error of v$.localRecord.type.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="internal_key">Internal Key</label>
          <input id="internal_key" type="text" :class="{'is-invalid': v$.localRecord.internal_key.$invalid}" class="form-control"
          v-model="localRecord.internal_key"/>
          <div v-for="error of v$.localRecord.internal_key.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="serial_number">Serial Number</label>
          <input id="serial_number" type="text" :class="{'is-invalid': v$.localRecord.serial_number.$invalid}" class="form-control"
          v-model="localRecord.serial_number"/>
          <div v-for="error of v$.localRecord.serial_number.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="model">Model</label>
          <input id="model" type="text" :class="{'is-invalid': v$.localRecord.model.$invalid}" class="form-control"
          v-model="localRecord.model"/>
          <div v-for="error of v$.localRecord.model.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
        </div>
        <div class="mb-3 col-md-4">
          <label for="location">Location</label>
          <input id="location" type="text" class="form-control"
          v-model="localRecord.location"/>
        </div>
        <div class="mb-3 col-md-4">
          <label for="state">State</label>
          <select id="state" class="form-control" v-model="localRecord.state">
            <option>Other</option>
            <option>New</option>
            <option>Like new</option>
            <option>Damaged</option>
            <option>Discarted</option>
            <option>In repair</option>
          </select>
        </div>
        <div class="mb-3 col-md-12">
          <label for="name">Description</label>
          <div v-for="error of v$.localRecord.name.$silentErrors" :key="error.$uid">
            <div class="text-danger">{{ error.$message }}</div>
          </div>
          <input type="hidden" id="name" v-model="localRecord.name">
          <trix-editor input="name" @trix-change="localRecord.name = $event.target.value; $forceUpdate()"
          placeholder="Enter content" class="form-control"></trix-editor>
        </div>
      </div>
      <button :disabled="v$.localRecord.$invalid" type="button" class="btn btn-company-primary" @click="save()">
        <i class="fas fa-save"></i>
        Save
      </button>
      <button type="button" class="btn btn-company-secondary" @click="$emit('close')">
        <i class="fas fa-times"></i>
        Cancel
      </button>
    </div>
  </div>
</template>

<script>

import Validations from './Validations';
import Blinkable from '../../shared/Blinkable';

export default {
  mixins: [Blinkable, Validations],
  props: ['record'],
  data() {
    return {
      localRecord: structuredClone(this.record),
    };
  },
  methods: {
    save() {
      const event = this.localRecord.id ? 'update' : 'insert';
      this.$emit(event, this.localRecord);
    },
  },
};
</script>
