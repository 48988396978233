<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Birthdays</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12 m-auto mt-2">
              <Search
                :search.sync="search"
                :inputs="['users', 'onlyMyProjectsCheckbox']"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row" v-if="birthdaysByMonth">
      <div class="col-md-12 text-nowrap h-100 scroll-x">
        <table class="table">
          <tr>
            <th></th>
            <th>Person</th>
            <th>Current Age</th>
            <th>Date of Birth</th>
            <th>Next Birthday</th>
          </tr>
          <template v-for="row in birthdaysByMonth">
            <tr :key="`b-${row.month}-${index}`" v-for="(td, index) in row.data">
              <td style="writing-mode: vertical-rl; cursor: default" :rowspan="row.data.length" v-if="index === 0">{{ row.month }}</td>
              <td>{{td.name}}</td>
              <td>{{td.current_age}}</td>
              <td>{{$displayDate(td.date_of_birth)}}</td>
              <td>
                <span v-if="td.next_birthday">
                  <i v-if="isToday(td.next_birthday)" class="fa-solid fa-star text-warning"></i>
                  {{$displayDate(td.next_birthday)}} ({{ remainingDays(td.next_birthday) }})
                </span>
                <i v-else class="fas fa-circle text-danger" :title="`Birthday is not set for ${td.name}`"></i>
              </td>
            </tr>
          </template>
          <tr v-if="birthdaysByMonth && birthdaysByMonth.length === 0">
            <td class="text-center" colspan="4">No records found</td>
          </tr>
        </table>
      </div>
    </div>
    <Empty v-if="!birthdaysByMonth?.length" icon="fas fa-birthday-cake"/>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import { Search, SpinnerRouterLink } from '../shared';
import BIRTHDAYS_AND_ANNIVERSARIES_QUERY from './queries';

export default {
  components: { Search, SpinnerRouterLink },
  data() {
    return {
      search: { only_my_projects: false },
    };
  },
  computed: {
    birthdaysByMonth() {
      const months = _.compact(_.uniq(_.map(this.birthdays, (row) => (row.next_birthday ? this.monthNumberToEnglish(row.next_birthday) : null))));
      return months.map((month) => ({ month, data: this.birthdays.filter((row) => this.monthNumberToEnglish(row.next_birthday) === month) }));
    },
  },
  methods: {
    refresh() {
      this.$apollo.queries.birthdays.refetch();
    },
    isToday(date) {
      return dayjs(date, 'YYYY-MM-DD').format('MM-DD') === dayjs().format('MM-DD');
    },
    monthNumberToEnglish(date) {
      return dayjs(date, 'YYYY-MM-DD').format('MMMM');
    },
    remainingDays(endDate) {
      return dayjs(endDate, 'YYYY-MM-DD').endOf('day').fromNow();
    },
  },
  apollo: {
    birthdays: {
      query: BIRTHDAYS_AND_ANNIVERSARIES_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          ...this.search,
        };
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
};
</script>
