<template>
  <div class="company-modal">
    <div class="mb-5 card">
      <div class="card-header d-flex">
        <div class="col-6 p-0">
          <span>Holiday Calendar</span>
        </div>
      </div>
      <div class="card-body d-grid">
        <div>
          <table class="table table-striped-columns">
            <thead>
              <tr>
                <th class="col-md-2" >Country</th>
                <th class="col-md-1">Year</th>
                <th class="col">Holidays</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(countryData, index) in groupDataByCountry" :key="index">
                <td class="text-start h5">
                  {{countryData.flag}} {{ countryData.country }} ({{countryData.holidays.length}})
                </td>
                <td>{{ countryData.year}}</td>
                <td>
                  <span> {{ countryData.holidays.join(" , ") }} </span>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { DROPDOWNS, Flags } from '../../../shared';

export default {
  props: ['year', 'record'],
  data() {
    return {
      countriesQuery: DROPDOWNS,
    };
  },
  computed: {
    groupDataByCountry() {
      const uniqueCountries = _.uniq(_.map(this.record, 'country.name'));
      return uniqueCountries.map((country) => {
        const holidays = this.record.filter((r) => r.country.name === country);
        return {
          country, flag: Flags[country], year: this.year, holidays: holidays.map((h) => this.$displayDate(h.date)),
        };
      });
    },
  },
};
</script>
