<template>
  <div class="container-fluid">
    <div class="p-5 mb-4 bg-light rounded-3 text-center" v-if="show_thank_you_page">
      <h1>THANK YOU!</h1>
      <h2 class="text-success display-2">
        <i class="fas fa-check"></i>
      </h2>
      <p>Your feedback is very important for us</p>
    </div>
    <template v-else>
      <div class="text-center row pt-3">
        <div class="col-md-12">
          <h3>WE LOVE FEEDBACK</h3>
          <h6>It'll be really quick, we promise</h6>
        </div>
      </div>
      <hr>
      <div class="row">
        <div class="col-md-12">
          <div class="col">
            Client: {{clientName}}
          </div>
          <div class="col" v-if="projectName">
            Project: {{projectName}}
          </div>
          <div class="col">
            Date: {{date}}
          </div>
        </div>
      </div>
      <hr>
      <div class="border-top px-2 py-2 bg-company-secondary">
        <p class="h5">
          Thank you for choosing {{ company.name }} Nearshoring IT Services.<br>
          We’re always looking at how we can make your experience better.<br>
          We appreciate your input.
        </p>
      </div>
      <div class="text-start border-top">
        <div class="mb-0 h5 company-background-color px-3 py-2">1) Based on your most recent experiences, on a scale of 0-10, how likely would recommend {{ company.name }} to a friend or colleague?</div>
        <div class="row border-top d-flex align-items-center m-0">
            <div class="col border text-center fw-bolder sortable pb-3 pt-3 bg-company-primary-ligth" :key="`score-${index}`" :class="{'bg-company-secondary': form.score === index}" v-for="index in scale" @click="score(index)">
              {{index}}
            </div>
        </div>
      </div>
      <div class="border-top">
        <div class="text-center py-2">
          <div class="row">
            <div class="col text-start">
              (0: Not at all likely)
            </div>
            <div class="col text-end">
              (10: extremely likely)
            </div>
          </div>
        </div>
      </div>
      <div class="border-top">
        <h5 class="mb-0 company-background-color px-3 py-2">2) What’s the main reason for your Score?</h5>
        <div class="border-top py-2">
          <trix-editor @trix-change="form.reason = $event.target.value; $forceUpdate()"></trix-editor>
        </div>
      </div>
      <div class="d-flex justify-content-between pt-2 pb-3 border-top">
        <div>
          <p class="m-0">Warm Regards,</p>
          <p class="m-0">{{ company.name }} Team</p>
        </div>
        <div>
          <button :disabled="form.score === null" type="button" class="btn btn-company-primary" @click="save()" >
            <i v-if="loading" class="fas fa-sync fa-spin"></i>
            <i v-else class="fas fa-paper-plane"></i>
            Submit
          </button>
        </div>
      </div>
    </template>
  </div>
</template>

<script>

import dayjs from 'dayjs';
import { SUBMIT_NET_PROMOTER_SCORE_FORM } from './mutations';
import { ErrorHandler } from '../../shared';

export default {
  data() {
    return {
      company: window.Company,
      show_thank_you_page: false,
      loading: false,
      form: {
        score: null,
        reason: null,
      },
    };
  },
  computed: {
    localRecord() {
      return {
        client_id: this.$route.query.client_id ?? null,
        project_id: this.$route.query.project_id ?? null,
        signature: this.$route.query.signature ?? null,
        ...this.form,
      };
    },
    scale() {
      return _.range(11);
    },
    date() {
      return this.$displayDate(dayjs());
    },
    clientName() {
      return document.getElementById('client_name').value;
    },
    projectName() {
      return document.getElementById('project_name').value;
    },
  },
  methods: {
    score(selectedScope) {
      this.form.score = selectedScope;
      document.querySelector('trix-editor').focus();
    },
    save() {
      this.loading = true;
      this.$apollo.mutate({
        mutation: SUBMIT_NET_PROMOTER_SCORE_FORM,
        variables: {
          input: this.localRecord,
        },
      }).then(() => {
        this.$router.replace({ path: '/net_promoter_score' }).catch(() => {});
        this.show_thank_you_page = true;
      }).catch((error) => {
        ErrorHandler.handle(error, this);
      }).finally(() => {
        this.loading = false;
      });
    },
  },
};
</script>
