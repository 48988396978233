<template>
<div :id="record.id" class="col-md-3 pb-2">
  <Form v-if="is_editting"
    v-bind:record="record"
    @insert="insert"
    @close="is_editting = false"
  ></Form>
  <div v-if="!is_editting" class="card h-100 position-relative">
    <div class="card-body pb-0">
      <h5 :title="this.record.configItem.description" class="card-title fw-bold" :class="nameColorClass">
        {{record.configItem.name}}
      </h5>
      <h6 class="text-body-secondary">
        {{successMessage}}
      </h6>
      <h6 class="card-subtitle mt-2">
        Current Value: {{displayValue}}
      </h6>
      <p class="text-muted pt-2">
        {{record.configItem.description}}
      </p>
    </div>
    <div class="card-body">
      <button v-if="$can('CreateHealthScoreProjectValue')" class="btn btn-company-primary" @click="is_editting = true">
        <i class="fas fa-pen"></i>
        Check In
      </button>
    </div>
  </div>
</div>
</template>

<script>
import { CREATE_HEALTH_SCORE_PROJECT_VALUE } from './mutations';
import Form from './Form.vue';
import ErrorHandler from '../../../shared/ErrorHandler';

export default {
  components: { Form },
  props: ['record'],
  data() {
    return {
      is_editting: false,
    };
  },
  computed: {
    displayValue() {
      if (['is_true', 'is_false'].includes(this.record.configItem.success_operator)) {
        return this.record.value === 1 ? 'true' : 'false';
      }
      return this.record.value;
    },
    nameColorClass() {
      const { value } = this.record;
      const { configItem } = this.record;

      if (value === null || value === undefined) {
        return 'text-warning';
      }

      switch (configItem.success_operator) {
        case 'equals_to':
          if (value === configItem.success_value_a) {
            return 'text-success';
          }
          return 'text-danger';

        case 'greater_than':
          if (value > configItem.success_value_a) {
            return 'text-success';
          }
          return 'text-danger';

        case 'less_than':
          if (value < configItem.success_value_a) {
            return 'text-success';
          }
          return 'text-danger';

        case 'is_true':
          if (value === 1) {
            return 'text-success';
          }
          return 'text-danger';

        case 'is_false':
          if (value === 0) {
            return 'text-success';
          }
          return 'text-danger';

        case 'between':
          if (value >= configItem.success_value_a && value <= configItem.success_value_b) {
            return 'text-success';
          }
          return 'text-danger';

        default:
          return 'text-warning';
      }
    },
    successMessage() {
      const messages = {
        equals_to: `Value must be equal to ${this.record.configItem.success_value_a}`,
        greater_than: `Value must be greater than ${this.record.configItem.success_value_a}`,
        less_than: `Value must be less than ${this.record.configItem.success_value_a}`,
        between: `Value must be between ${this.record.configItem.success_value_a} and ${this.record.configItem.success_value_b}`,
        is_true: 'Value must be true',
        is_false: 'Value must be false',
      };
      return messages[this.record.configItem.success_operator];
    },
  },
  methods: {
    insert(healthScoreProjectValue) {
      const newHealthScoreProjectValue = healthScoreProjectValue;
      this.$apollo.mutate({
        mutation: CREATE_HEALTH_SCORE_PROJECT_VALUE,
        variables: {
          healthScoreProjectValue,
        },
      })
        .then(() => {
          this.is_editting = false;
          this.$emit('on-new-item', healthScoreProjectValue);
        }).catch((error) => {
          ErrorHandler.handle(error, this);
          this.selectedRecord = newHealthScoreProjectValue;
        });
    },
  },
};
</script>
