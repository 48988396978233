<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row mb-3">
          <div class="col-auto ms-3 p-0">
            <ul class="breadcrumb p-0 m-auto">
              <li class="breadcrumb-item">
                <router-link to="/home">
                  <i class="fas fa-home"></i>
                </router-link>
              </li>
              <li class="breadcrumb-item active fw-bold" aria-current="page">
                Indicator Comparator
              </li>
            </ul>
          </div>
          <Search :search.sync="search" :inputs="['dateRanges']" :default_values="defaultDates"></Search>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <div class="btn-group dropright">
                <button type="button" class="btn btn-secondary dropdown-toggle" data-bs-toggle="dropdown" aria-haspopup="true"
                  aria-expanded="false">
                  Indicators
                </button>
                <div class="dropdown-menu pt-0" v-if="indicatorsDropDown">
                  <span class="dropdown-item sortable bg-dark text-white">Monthly Indicators</span>
                  <span :key="indicator.id" class="dropdown-item sortable" v-for="indicator in monthlyIndicators"
                    @click="toggleIndicator(indicator)">
                    <i class="fas fa-check text-success me-1" v-if="isSelected(indicator)"></i>
                    <i class="fas fa-times me-1" v-else></i>
                    {{indicator.name}}
                  </span>
                  <span class="dropdown-item sortable bg-dark text-white">Weekly Indicators</span>
                  <span :key="indicator.id" class="dropdown-item sortable" v-for="indicator in weeklyIndicators"
                    @click="toggleIndicator(indicator)">
                    <i class="fas fa-check text-success me-1" v-if="isSelected(indicator)"></i>
                    <i class="fas fa-times me-1" v-else></i>
                    {{indicator.name}}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12 text-center">
        <div v-if="selectedIndicators.length === 0" class="p-5 mb-4 rounded-3">
          <h1>Please select one or more indicators to proceed.</h1>
          <Empty icon="fas fa-balance-scale"/>
        </div>
        <div id="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>
import dayjs from 'dayjs';
import Highcharts from 'highcharts';
import { Search } from '../../shared';
import { SearchBuilder, INDICATORS } from '../../../shared';
import COMPARE_INDICATORS_QUERY from './queries';

export default {
  components: { Search },
  apollo: {
    compareIndicators: {
      query: COMPARE_INDICATORS_QUERY,
      variables() {
        return {
          ids: _.map(this.selectedIndicators, 'id'),
          ...SearchBuilder.build(this.search),
        };
      },
      errorPolicy: 'all',
      error(error) {
        this.$toasted.error(error.message);
      },
    },
    indicatorsDropDown: {
      query: INDICATORS,
      fetchPolicy: 'no-cache',
      variables() {
        return { orderBy: [{ column: 'id', order: 'ASC' }] };
      },
    },
  },
  data() {
    return {
      selectedIndicators: [],
      search: { },
    };
  },
  computed: {
    weeklyIndicators() {
      return this.indicatorsDropDown.filter((i) => i.frequency === 'weekly');
    },
    monthlyIndicators() {
      return this.indicatorsDropDown.filter((i) => i.frequency === 'monthly');
    },
    subtitle() {
      return this.series.map((s) => s.name).join(' vs ');
    },
    categories() {
      return _.map(this.compareIndicators, 'created_at');
    },
    series() {
      return this.selectedIndicators.map((selectedIndicator) => ({ name: selectedIndicator.name, data: _.map(this.compareIndicators, selectedIndicator.dynamic_column_name) }));
    },
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'year').startOf('isoweek').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
      };
    },
  },
  watch: {
    compareIndicators() {
      this.drawChart();
    },
  },
  methods: {
    toggleIndicator(indicator) {
      const index = this.selectedIndicators.indexOf(indicator);
      if (index > -1) {
        this.selectedIndicators.splice(index, 1);
      } else {
        this.selectedIndicators.push(indicator);
      }
    },
    isSelected(indicator) {
      return this.selectedIndicators.includes(indicator);
    },
    drawChart() {
      if (this.selectedIndicators.length === 0) {
        return;
      }
      Highcharts.chart('chart', {
        chart: {
          type: 'line',
          height: '40%',
        },
        title: {
          text: 'Indicator Comparator',
        },
        subtitle: {
          text: this.subtitle,
        },
        yAxis: {
          title: {
            text: '',
          },
          labels: {
            enabled: true,
          },
        },
        xAxis: {
          categories: this.categories,
          lineWidth: 0,
        },
        plotOptions: {
          line: {
            dataLabels: {
              enabled: true,
            },
          },
        },
        series: this.series,
      });
    },
  },
};
</script>
