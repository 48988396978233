 <template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Timeline</SpinnerRouterLink>
          </div>
        </div>
        <div class="container-fluid">
          <div class="row">
            <div class="col-md-12">
              <Search
                :search.sync="search"
                :inputs="['dateRanges', 'users']"
                :default_values="defaultDates"
              ></Search>
            </div>
          </div>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12">
        <div id="chart"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Highcharts from 'highcharts';
import * as exporter from 'highcharts/modules/xrange';
import dayjs from 'dayjs';
import TIMELINE_QUERY from './queries';
import { SpinnerRouterLink, Search } from '../../shared';

exporter.default(Highcharts);

export default {
  components: { Search, SpinnerRouterLink },
  data() {
    return {
      search: {},
    };
  },
  computed: {
    defaultDates() {
      return {
        start_date: dayjs().subtract(1, 'year').format('YYYY-MM-DD'),
        end_date: dayjs().format('YYYY-MM-DD'),
      };
    },
  },
  apollo: {
    timeline: {
      query: TIMELINE_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          ...this.search,
        };
      },
      skip() {
        return !(this.search.start_date || this.search.end_date);
      },
      error(error) {
        this.$toasted.error(error.message);
        this.timeline = [];
      },
    },
  },
  watch: {
    timeline() {
      if (!this.timeline) {
        this.timeline = [];
      }
      const users = this.timeline.map((x) => x.user);
      const bench = this.timeline.filter((element) => element.project === 'Bench');
      const projects = this.timeline.filter((element) => element.project !== 'Bench');
      this.drawChart(users.filter((v, i, a) => a.indexOf(v) === i), bench, projects);
    },
  },
  created() {
    ['user', 'start_date', 'end_date'].forEach((key) => {
      if (this.$route.query[key]) {
        this[key] = this.$route.query[key];
      }
    });
  },
  methods: {
    drawChart(users, bench, projects) {
      Highcharts.setOptions({
        colors: [
          '#90ee7e', '#f45b5b',
        ],
      });
      Highcharts.chart('chart', {
        chart: {
          type: 'xrange',
          height: (this.search.user) ? 250 : 900,
        },
        title: {
          text: 'Timeline',
        },
        xAxis: {
          type: 'datetime',
        },
        yAxis: {
          title: {
            text: '',
          },
          categories: users,
          reversed: true,
        },
        plotOptions: {
          xrange: {
            borderRadius: 0,
            borderWidth: 2,
            grouping: false,
            dataLabels: {
              align: 'center',
              enabled: true,
              format: '{point.project}',
            },
            colorByPoint: false,
          },
        },
        tooltip: {
          headerFormat: '<span style="font-size: 0.85em">{point.x} - {point.x2}</span><br/>',
          pointFormat: '<br/><h5>Project Name: {point.project}</h5><br/><span style="color:{series.color}">●</span> {series.name}: <b>{point.yCategory}</b><br/>',
        },
        series: [
          {
            borderColor: 'gray',
            name: 'In project',
            pointWidth: 20,
            data: projects,
            dataLabels: {
              enabled: true,
            },
          },
          {
            borderColor: 'red',
            name: 'Bench',
            pointWidth: 20,
            data: bench,
            dataLabels: {
              enabled: true,
            },
          },
        ],
      });
    },
  },
};
</script>
