export const WEEKLY_INTERNAL_CALL = `<div>
  <h2>Weekly Internal Call</h2>
  <h3><strong>Attendees:</strong></h3>
  {{Attendees}}

  <h3><strong>Weekly tasks completed and WIP</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Story points and/or completed tickets</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Processes implemented</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Upgrades implemented</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>No Bugs? increased detection? decreased incidence</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Savings?</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Measurable results?</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Blockers encountered?</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Project challenges?</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Technologies utilized?</strong></h3>
  <ul><li>[add item here]</li></ul>
</div>`;

export const KICKOFF_CALL = `<div>
  <h2>Kickoff Call</h2>
  <h3><strong>Attendees:</strong></h3>
  {{Attendees}}
  {{pocs}}

  <h3><strong>Important Dates:</strong></h3>
  <ul><li>[Date] - [Meaningful description here]</li></ul>

  <h3><strong>Project Goals and Objectives:</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Business/Industry background:</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Position goals & expectations:</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Client success concept:</strong></h3>
  <ul><li>[add item here]</li></ul>
</div>`;

export const CMS_TOUCH_POINT = `<div>
  <h2>CMS Touch point</h2>
  <h3><strong>Attendees:</strong></h3>
  {{managers_and_pocs}}

  <h3><strong>Feedback</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Project Progress</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Upsell Opportunities</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Risks Identified</strong></h3>
  <ul><li>[add item here]</li></ul>

  <h3><strong>Action Items & Deadlines</strong></h3>
  <ul><li>[add item here]</li></ul>
</div>`;
