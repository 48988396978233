<template>
  <div>
    <nav>
      <div class="breadcrumb p-2">
        <div class="row">
          <div class="col-auto ms-3 p-0">
            <SpinnerRouterLink :loading="$apollo.loading">Technology Distribution</SpinnerRouterLink>
          </div>
        </div>
        <div class="col-md-10 m-auto p-0">
          <Search
            :search.sync="search" :inputs="['dateRanges', 'chartType']"
          ></Search>
        </div>
      </div>
    </nav>
    <div class="row">
      <div class="col-md-12">
        <div id="chart" class="border"></div>
      </div>
    </div>
  </div>
</template>

<script>

import Highcharts from 'highcharts';
import loadWordcloud from 'highcharts/modules/wordcloud';
import loadHighchartsMore from 'highcharts/highcharts-more';
import loadTreemap from 'highcharts/modules/treemap';
import TECHNOLOGY_DISTRIBUTION_QUERY from './queries';
import SearchBuilder from '../../../shared/SearchBuilder';
import { SpinnerRouterLink, Search } from '../../shared';

loadHighchartsMore(Highcharts);
loadWordcloud(Highcharts);
loadTreemap(Highcharts);

export default {
  components: { Search, SpinnerRouterLink },
  data() {
    return {
      search: {
        percentile: '35', chart_type: 'wordcloud',
      },
    };
  },
  apollo: {
    technology_distribution: {
      query: TECHNOLOGY_DISTRIBUTION_QUERY,
      errorPolicy: 'all',
      variables() {
        return {
          ...SearchBuilder.build(this.search),
        };
      },
      skip() {
        return !(this.search.percentile || this.search.chart_type);
      },
      error(error) {
        this.$toasted.error(error.message);
      },
    },
  },
  watch: {
    chartType() {
      if (!this.technology_distribution) {
        return;
      }
      this.drawChart();
    },
    percentileValue() {
      if (!this.technology_distribution) {
        return;
      }
      this.drawChart();
    },
    technology_distribution() {
      if (this.technology_distribution.length > 0) {
        this.drawChart();
      }
    },
  },
  computed: {
    chartType() {
      return this.search.chart_type;
    },
    percentileValue() {
      return this.search.percentile;
    },
    chartTitle() {
      return { text: '' };
    },
    series() {
      return [{
        name: '',
        data: this.technology_distribution.map((t) => ({ name: t.name, value: t.frequency, weight: t.frequency })),
      }];
    },
    min() {
      return _.minBy(this.technology_distribution, 'frequency').frequency;
    },
    max() {
      return _.maxBy(this.technology_distribution, 'frequency').frequency;
    },
  },
  methods: {
    filterByPercentile(percentile) {
      const sortedValues = _.sortedUniq(_.map(this.technology_distribution, 'frequency')).reverse();
      const index = (percentile / 100) * sortedValues.length;
      if (Math.floor(index) === index) {
        return (sortedValues[index - 1] + sortedValues[index]) / 2;
      }
      return sortedValues[Math.floor(index)];
    },
    bubbleChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'packedbubble',
          height: '50%',
        },
        title: this.chartTitle,
        tooltip: {
          headerFormat: '',
          useHTML: true,
          pointFormat: '<b>{point.name}:</b> {point.value} projects',
        },
        legend: {
          enabled: false,
        },
        plotOptions: {
          packedbubble: {
            maxSize: '200%',
            zMin: this.min,
            zMax: this.max,
            layoutAlgorithm: {
              splitSeries: false,
              gravitationalConstant: 0.02,
            },
            dataLabels: {
              enabled: true,
              format: '{point.name}',
              filter: {
                property: 'y',
                operator: '>=',
                value: this.filterByPercentile(this.search.percentile),
              },
            },
          },
        },
        series: this.series,
      });
    },
    wordCloudChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'wordcloud',
          height: '50%',
        },
        title: this.chartTitle,
        series: this.series,
      });
    },
    treeMapChart() {
      Highcharts.chart('chart', {
        chart: {
          type: 'treemap',
          height: '50%',
        },
        series: [{
          type: 'treemap',
          layoutAlgorithm: 'squarified',
          data: this.series[0].data,
        }],
        title: this.chartTitle,
      });
    },
    drawChart() {
      if (this.chartType === 'bubble') {
        this.bubbleChart();
        return;
      }
      if (this.chartType === 'treemap') {
        this.treeMapChart();
        return;
      }
      this.wordCloudChart();
    },
  },
};
</script>
